import { InjectedConnector } from "@web3-react/injected-connector";
require("dotenv").config();

const { REACT_APP_NODE_ENV, REACT_APP_SUPPORTED_TESTNETS } = process.env;
const ChainIds = {
    /* mainnet: 1,
    ropsten: 3,
    rinkeby: 4,
    goerli: 5,
    kovan: 42, */
    mumbai: 80001
};

const supportedChainIds = REACT_APP_SUPPORTED_TESTNETS.split(",").map((id) => ChainIds[id]);

export const connector = new InjectedConnector({
    supportedChainIds
});
