import { Box, Grid, Stack } from '@mui/material'
import React from 'react'
import PortfolioCoinsCard from './PortfolioCoinsCard'

export default function PortfolioList({ coins }) {
    return (
        <Box>
            <Box
                sx={{
                    display: {
                        xs: 'block',
                        md: 'none'
                    }
                }}
            >
                <Stack spacing={2}>
                    {coins?.map((coin, index) => (
                        <Box
                            key={index}
                        >
                            <PortfolioCoinsCard coin={coin} />
                        </Box>
                    ))}
                </Stack>
            </Box>
            <Box
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                }}
            >
                <Grid container spacing={1}>
                    {coins?.map((coin, index) => (
                        <Grid key={index} item xs={12 / 3}>
                            <PortfolioCoinsCard coin={coin} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}
