import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import { Avatar, Box, Stack, TextField, CardContent, Typography, Card } from "@mui/material";
import Reducers from "../../API/Reducers";
import StyledButton from "./StyledButton";
import StyledTextField from "./StyledTextField";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function ({ onCoinsListChanged }) {
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
        onCoinsListChanged(right)
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        onCoinsListChanged(right)
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        onCoinsListChanged(right)
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
        onCoinsListChanged(right)
    };

    React.useEffect(() => {
        async function getData() {
            setLeft(await Reducers.getCoins());
        }

        if (left.length === 0)
            getData()
    }, [])

    const Search = () => (
        <Box>
            <StyledTextField
                variant='outlined'
                fullWidth
                label='Search'
                placeholder='Search for coins'
            />
        </Box>
    )

    const customList = (items) => (
        <Card sx={{
            width: '100%',
            height: 280,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            background: (theme) => theme.palette.primary.darker
        }}>
            <CardContent>
                {items.length > 0 ?
                    <>
                        <Search />
                        <List dense component="div" role="list">
                            {items.map((value) => {
                                const labelId = `transfer-list-item-${value.crypto_id}-label`;
                                return (
                                    <ListItem
                                        key={value.crypto_id}
                                        role="listitem"
                                        button
                                        onClick={handleToggle(value)}
                                    >
                                        <Stack
                                            direction='row'
                                            alignItems='center'
                                            spacing={1}
                                        >
                                            <Stack
                                                direction='row'
                                                alignItems='center'
                                                spacing={1}
                                            >
                                                <Checkbox
                                                    checked={checked.indexOf(value) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        color: (theme) => theme.palette.primary.light,
                                                        '&.Mui-checked': {
                                                            color: (theme) => theme.palette.secondary.main,
                                                        },
                                                    }}
                                                />
                                                <Avatar
                                                    src={value.crypto_image}
                                                    sx={{
                                                        height: 25,
                                                        width: 25,
                                                        backgroundColor: (theme) => theme.palette.primary.light + "50"
                                                    }}
                                                />
                                            </Stack>

                                            <Typography
                                                variant='button'
                                            >
                                                <ListItemText id={labelId} primary={value.crypto_name} />
                                            </Typography>
                                            <Typography
                                                variant='button'
                                            >
                                                ({value.crypto_symbol})
                                            </Typography>
                                        </Stack>
                                    </ListItem>
                                );
                            })}
                            <ListItem />
                        </List>
                    </>
                    :
                    <Typography
                        variant='button'
                        align='center'
                        sx={{
                            p: 2,
                            pt: '25%'
                        }}
                    >
                        You haven't added any tradeable coins in the event
                    </Typography>
                }
            </CardContent>
        </Card>
    );

    // const customList = (items) => (
    //   <Paper
    //     sx={{
    //       width: "100%",
    //       height: 230,
    //       overflow: "auto",
    //       display: "flex",
    //       flexDirection: "column",
    //     }}
    //   >
    //     {items.length > 0 ? (
    //       <>
    //         <Search />

    //         <List dense component="div" role="list">
    //           {items
    //             .filter((value) =>
    //               value?.crypto_name
    //                 .toLowerCase()
    //                 .includes(searchValue.toLowerCase())
    //             )
    //             .map((value) => {
    //               const labelId = `transfer-list-item-${value.crypto_id}-label`;
    //               return (
    //                 <ListItem
    //                   key={value.crypto_id}
    //                   role="listitem"
    //                   button
    //                   onClick={handleToggle(value)}
    //                 >
    //                   <Stack direction="row" alignItems="center" spacing={1}>
    //                     <Stack direction="row" alignItems="center" spacing={2}>
    //                       <Checkbox
    //                         checked={checked.indexOf(value) !== -1}
    //                         tabIndex={-1}
    //                         disableRipple
    //                         inputProps={{
    //                           "aria-labelledby": labelId,
    //                         }}
    //                         sx={{
    //                           color: (theme) => theme.palette.primary.light,
    //                           "&.Mui-checked": {
    //                             color: (theme) => theme.palette.secondary.main,
    //                           },
    //                         }}
    //                       />
    //                       <Avatar
    //                         src={value.crypto_image}
    //                         sx={{
    //                           height: 25,
    //                           width: 25,
    //                         }}
    //                       />
    //                     </Stack>

    //                     <Typography variant="button">
    //                       <ListItemText
    //                         id={labelId}
    //                         primary={value.crypto_name}
    //                       />
    //                     </Typography>
    //                   </Stack>
    //                 </ListItem>
    //               );
    //             })}
    //           <ListItem />
    //         </List>
    //       </>
    //     ) : (
    //       <Typography
    //         variant="button"
    //         align="center"
    //         sx={{
    //           p: 2,
    //           pt: "25%",
    //         }}
    //       >
    //         You haven't added any tradeable coins in the event
    //       </Typography>
    //     )}
    //   </Paper>
    // );

    return (
        <Box>
            <Box
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                }}
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={5}
                >
                    {customList(left)}
                    <Stack spacing={3}>
                        <StyledButton
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            aria-label="move all right"
                        >
                            ≫
                        </StyledButton>
                        <StyledButton
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </StyledButton>
                        <StyledButton
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </StyledButton>
                        <StyledButton
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={right.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </StyledButton>
                    </Stack>
                    {customList(right)}
                </Stack>
            </Box>
            <Box
                sx={{
                    display: {
                        xs: 'block',
                        md: 'none'
                    }
                }}
            >
                <Stack
                    spacing={5}
                >
                    {customList(left)}
                    <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={3}>
                        <StyledButton
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            aria-label="move all right"
                        >
                            ≫
                        </StyledButton>
                        <StyledButton
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </StyledButton>
                        <StyledButton
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </StyledButton>
                        <StyledButton
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={right.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </StyledButton>
                    </Stack>
                    {customList(right)}
                </Stack>
            </Box>
        </Box>
    );
}
