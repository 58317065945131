import { Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import Lottie from 'react-lottie';
import walletAnimation from '../../animations/wallet.json'

export default function InactiveAccount() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: walletAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <Paper
            sx={{
                p: 3,
                background: (theme) => theme.palette.primary.darker /* + "90" */,
                m: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            {/* <img
                src='/assets/inactive.png'
                height='200vw'
            /> */}
            <Lottie options={defaultOptions} width={300} height={250} />
            <Typography
                variant='button'
            >
                You have not connected to a wallet. Connect to a wallet to see your account details.
            </Typography>
        </Paper>
    )
}
