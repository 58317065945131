import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Avatars from "../Events/Avatars";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import axios from "axios";
import Moment from "react-moment";

const columns = [
  {
    id: "coin",
    label: "Coin",
    minWidth: 20,
    align: "center",
    //display: "flex",
    //justifyContent: "center",
  },
  {
    id: "quantity",
    label: "Quantity",
    minWidth: 20,
    align: "center",
  },
  {
    id: "price",
    label: "Price",
    minWidth: 20,
    align: "center",
  },
  {
    id: "fundsUsed",
    label: "Funds Used",
    minWidth: 20,
    align: "center",
  },
  {
    id: "transaction_type",
    label: "Transaction Type",
    minWidth: 20,
    align: "center",
    // padding: 0,
  },
  {
    id: "transaction_date",
    label: "Transaction Date",
    minWidth: 20,
    align: "center",
    //display: "flex",
    // justifyContent: 'flex-end'
  },
];

function createData(
  coin,
  quantity,
  price,
  fundsUsed,
  transaction_type,
  transaction_date
) {
  //const density = population / size;tradable_coins
  return {
    coin,
    quantity,
    price,
    fundsUsed,
    transaction_date,
    transaction_type,
  };
}

// console.log('row orders', rows);

export default function OrdersTable({ orders }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rows = orders?.map((obj) =>
    createData(
      // `${obj.transaction_id}`,
      <Stack>
        <Avatars
          images={[`${obj.crypto_image}`]}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          name={obj.crypto_symbol}
        />
        {obj.crypto_name} ({obj.crypto_symbol})
      </Stack>,
      `${obj.crypto_quantity}`,
      `$ ${obj.crypto_current_price}`,
      `$ ${(
        Number(obj.crypto_current_price) * Number(obj.crypto_quantity)
      ).toFixed(8)}`,
      `${obj.transaction_type.toUpperCase()}`,
      <Moment format="LLL">{obj.createdAt}</Moment>
    )
  );
  // console.log(rows, "rows");

  // console.log("orders fetched", orders);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // console.log('rows val',rows)
  return (
    <Box
      sx={{
        mb: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.primary.darker,
          // justifyContent: "center",
        }}
      >
        <TableContainer sx={{ maxHeight: "100vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      display: column.display,
                      justifyContent: column.justifyContent,
                      alignItems: column.alignItems,
                      minWidth: column.minWidth,
                      position: column.position,
                      right: column.right,
                      tableLayout: "fixed",
                      margin: column.margin,
                      padding: column.padding,
                    }}
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.light,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                backgroundColor: (theme) => theme.palette.primary.darker,
              }}
            >
              {rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];

                        // console.log('val',value)

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              width: "20%",
                            }}
                          >
                            {/* {column.format && typeof value === "number"
                              ? column.format(value)
                              : value} */}
                            {value === "BUY" ? (
                              <>
                                <Stack
                                  direction="row"
                                  sx={{
                                    // display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: (theme) =>
                                        value === "BUY"
                                          ? theme.palette.misc.success
                                          : theme.palette.misc.error,
                                      fontWeight: "bold",
                                      //display: "flex",
                                      //justifyContent: "center",
                                      //alignItems: "center",
                                    }}
                                  >
                                    {value}
                                  </Typography>
                                </Stack>
                              </>
                            ) : value === "SELL" ? (
                              <Stack
                                direction="row"
                                sx={{
                                  // display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: (theme) =>
                                      value === "BUY"
                                        ? theme.palette.misc.success
                                        : theme.palette.misc.error,
                                    fontWeight: "bold",
                                    // display: "flex",
                                    // justifyContent: "center",
                                    // alignItems: "center",
                                  }}
                                >
                                  {value}
                                </Typography>
                              </Stack>
                            ) : (
                              value
                            )}
                            {/* {console.log("value", value)} */}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
