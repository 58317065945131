import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Typography, Box, Drawer, Grid } from "@mui/material";
import StorageKeys from "../../storage.keys";
import axios from "axios";
import Tabs from "../Events/ResponsiveTabs";
import ResponsiveLoading from "../Events/ResponsiveLoading";
import FlyingUp from "../Animations/FlyingUp";
import Event from "./Event";
import EventDetails from "../EventDetails";
import { useWindowDimensions } from "../EventDetails/constants";
import EmptyPage from "../Events/EmptyPage";
import { useWeb3React } from "@web3-react/core";
import InactiveAccount from "./InactiveAccount";
import Reducers from "../../API/Reducers";
import { useNavigate } from "react-router-dom";
import Contracts from "../../functions/contract.functions";
import JoinEventSteps from "../Modals/JoinEvent/JoinEventSteps";
import JoiningCompleted from "../Modals/JoinEvent/JoiningCompleted";
import BasicLoader from "../Modals/BasicLoader";
import { useStorage } from "../../hooks/storage.hooks";

function Account() {
  const { width, height } = useWindowDimensions();

  const approvalRef = useRef();
  const smartContractRef = useRef();
  const joiningEventRef = useRef();

  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});

  const { active, account } = useWeb3React();

  const navigate = useNavigate();
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleTabsChanged = (index) => {
    setTab(index);
  };

  const handleEventClicked = (event) => {
    if (event.is_joined && event.status === "ongoing")
      navigate(`/event/${event.event_id}/${event.vestibule_id}`);
  };
  const [user_id] = useStorage(StorageKeys.USER_ID);
  const [modal, setModal] = useState({ open: false, error: false });
  const [completed, setCompleted] = useState(false);

  useLayoutEffect(() => {
    if (!modal.open) {
      if (!loading) setLoading(true);
      async function getDetails() {
        const response = await Reducers.getMyEvents(user_id);
        console.log("Res ", response);
        setEvents(response);
        setLoading(false);
      }
      //setTimeout(() => {
      getDetails();
      //}, 2000)
    }
  }, [modal, active, user_id]);

  /* const [modalActive, setModalActive] = useState(0)

	const onApprovalSuccess = () => {
		if (approvalRef.current) {
			approvalRef.current.style.background = "#46E7A5";
			approvalRef.current.style.borderColor = "#46E7A5";
			approvalRef.current.firstChild.innerHTML = "Assets Approved";
		}
		setModalActive(1)
	}

	const onValidateContractSuccess = () => {
		if (smartContractRef.current) {
			smartContractRef.current.style.background = "#46E7A5";
			smartContractRef.current.style.borderColor = "#46E7A5";
			smartContractRef.current.firstChild.innerHTML = "Smart contract validated";
		}
		setModalActive(2)
	}

	const onJoiningEventSuccess = () => {
		if (joiningEventRef.current) {
			joiningEventRef.current.style.background = "#46E7A5";
			joiningEventRef.current.style.borderColor = "#46E7A5";
			joiningEventRef.current.firstChild.innerHTML = "Joined Successfully";
		}
		setModalActive(3)
	}

	const [joinCompletedModal, setJoinCompletedModal] = useState(false)

	const onJoiningCompleted = () => {
		setJoinCompletedModal(true)
		setTimeout(() => {
			setJoinCompletedModal(false)
		}, 2000)
	}

	React.useEffect(() => {
		setModalActive(0)
	}, [completed]) */

  const [currEvent, setCurrEvent] = useState({});

  const handleJoinClicked = async (event) => {
    setCompleted(false);
    const {
      contract_address,
      event_id,
      joining_fees_in_wei,
      asset,
      vestibule_id,
      is_joined,
      status,
    } = event;
    setCurrEvent(event);
    try {
      if (!is_joined) {
        setModal({
          open: true,
          error: false,
        });
        await Contracts.joinTournament(
          {
            contract_address,
            joining_fees_in_wei,
            asset,
            isNativeAsset: asset === "matic",
          },
          ({ stage }) => {
            /* switch (stage) {
						case 1:
							setTimeout(() => {
								onApprovalSuccess()
							}, 100)
							break;
						case 2:
							onValidateContractSuccess()
							break;
					} */
          }
        );

        const body = {
          user_id,
          event_id,
        };

        await Reducers.joinEvent(body);
        //onJoiningEventSuccess()
        setCompleted(true);
        //setModal(false)

        //onJoiningCompleted()
      }
    } catch (err) {
      //setModalActive(0)
      setCompleted(true);
      setModal({ open: true, error: err });
    }
  };

  const handleModalClose = () => {
    if (completed) setModal({ ...modal, open: false });
  };

  const onFinished = () => {
    if (currEvent.status === "ongoing")
      navigate(`/event/${currEvent.event_id}/${currEvent.vestibule_id}`);
    else handleModalClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: {
          xs: 7,
          md: 8,
        },
      }}
    >
      {/* <JoiningCompleted
				open={joinCompletedModal}
				onClose={() => setJoinCompletedModal(false)}
			/> */}
      {/* <JoinEventSteps
				open={modal}
				ref1={approvalRef}
				ref2={smartContractRef}
				ref3={joiningEventRef}
				onClose={handleModalClose}
				active={modalActive}
			/> */}
      <BasicLoader
        open={modal.open}
        error={modal.error}
        completed={completed}
        onClose={handleModalClose}
        onFinished={onFinished}
      />
      {active ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: {
              xs: "center",
              md: "start",
            },
            width: "100%",
          }}
        >
          <Tabs
            opts={["Created Events", "Joined Events"]}
            onTabsChanged={handleTabsChanged}
          />
          <Box
            sx={{
              mt: 2,
              mb: 4,
              px: {
                md: 2,
                xs: 1,
              },
              width: "100%",
            }}
          >
            {!loading ? (
              tab === 0 ? (
                <FlyingUp>
                  {events?.created_events.length > 0 ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Grid container spacing={1}>
                          {events?.created_events.map((event) => (
                            <Grid
                              key={event.event_id}
                              item
                              xl={12 / 3}
                              md={12 / 2}
                              xs={12}
                            >
                              <Event
                                event={event}
                                onClick={() => {
                                  handleEventClicked(event);
                                }}
                                onJoinClicked={() => handleJoinClicked(event)}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                      {/* <Box
												sx={{
													display: {
														xs: 'block',
														md: 'none'
													},
												}}
											>
												{events?.created_events.map((event) => (
													<Event
														key={event.event_id}
														event={event}
														onClick={() => {
															handleEventClicked(event);
														}}
														onJoinClicked={handleJoinClicked}
													/>

												))}
											</Box> */}
                    </>
                  ) : (
                    <EmptyPage type={"created"} />
                  )}
                </FlyingUp>
              ) : (
                <FlyingUp>
                  {events?.joined_events.length > 0 ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Grid container spacing={1}>
                          {events?.joined_events.map((event) => (
                            <Grid
                              key={event.event_id}
                              item
                              xl={12 / 3}
                              md={12 / 2}
                              xs={12}
                            >
                              <Event
                                event={event}
                                onClick={() => {
                                  handleEventClicked(event);
                                }}
                                onJoinClicked={() => handleJoinClicked(event)}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                      {/* <Box
												sx={{
													display: {
														xs: 'block',
														md: 'none'
													},
												}}
											>
												{events?.joined_events.map((event) => (
													<Event
														key={event.obj.event_id}
														event={event.obj}
														onClick={() => {
															handleEventClicked(event.obj);
														}}
														onJoinClicked={() => handleJoinClicked(event.obj)}
													/>

												))}
											</Box> */}
                    </>
                  ) : (
                    <EmptyPage type={"joined"} />
                  )}
                </FlyingUp>
              )
            ) : (
              <ResponsiveLoading />
            )}
          </Box>
        </Box>
      ) : (
        <InactiveAccount />
      )}
      {/* <Drawer
				anchor={width < 400 ? "bottom" : "right"}
				elevation={10}
				open={drawerOpen}
				variant="temporary"
				onClose={toggleDrawer}
				sx={{
					"-webkit-backdrop-filter": "blur(4px)",
					backdropFilter: "blur(4px)",
				}}
			>
				<EventDetails event={selectedEvent} toggleDrawer={toggleDrawer} />
			</Drawer> */}
    </Box>
  );
}

export default Account;
