import { Avatar, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { trim } from "../../utils/number.utils";

export default function PortfolioCoinsCard({ coin }) {
  return (
    <Paper
      sx={{
        p: 2,
        background: (theme) => theme.palette.primary.darker /* + "90" */,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <Avatar
            src={coin.crypto_image}
            sx={{
              height: "40px",
              width: "40px",
              backgroundColor: (theme) => theme.palette.primary.light + "50",
            }}
          />
          <Stack>
            <Typography variant="h6" fontWeight="bold" fontSize={16}>
              {trim(coin.quantity)}&nbsp;{coin.crypto_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.primary.suppressedText,
                letterSpacing: 0.5,
              }}
              textTransform="uppercase"
            >
              ${trim(coin.average_buy_price)}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography
            variant="body2"
            textTransform="uppercase"
            fontWeight="500"
            sx={{
              color: (theme) =>
                Number(coin.crypto_profit) < 0
                  ? theme.palette.misc.error
                  : theme.palette.misc.success,
            }}
          >
            {coin.crypto_profit}%
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}
