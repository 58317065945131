import { Box } from "@mui/material";
import React from "react";
import OrdersTable from "./OrdersTable";
import OrdersCard from "./OrdersCard";
import axios from "axios";
import Moment from "react-moment";
import Reducers from "../../API/Reducers";
import NoOrders from "./NoOrders";

export default function ResponsiveOrdersTable({ orders }) {
  return (
    <Box>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <OrdersTable
          orders={orders}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            md: "none",
          },
        }}
      >
        {orders &&
          orders
            /*.reduce((prev, arr) => prev.concat(arr), [])*/
            .map((obj) => (
              <OrdersCard
                date={obj.createdAt}
                symbol={obj.crypto_symbol}
                name={obj.crypto_name}
                cryptoCount={obj.crypto_quantity}
                price={obj.crypto_current_price}
                cryptoImage={obj.crypto_image}
                transactionType={obj.transaction_type}
                cryptoName={obj.crypto_name}
              />
            ))}
      </Box>
    </Box>
  );
}
