import { Avatar, Stack, Typography } from '@mui/material'
import React from 'react'
import Base from '../Base'

export default function JoiningCompleted({ onClose, open }) {
    return (
        <Base
            onClose={onClose}
            open={open}
        >
            <Stack
                spacing={2}
                direction='row'
                alignItems='center'
            >
                <Avatar
                    src='/assets/check.png'
                    sx={{
                        width: 30,
                        height: 30
                    }}
                />
                <Typography
                    variant='button'
                    sx={{
                        fontSize: 22
                    }}
                >
                    Joined Event Successfully
                </Typography>
            </Stack>
        </Base>
    )
}
