import { Box } from "@mui/material";
import React from "react";
import PerformingCoins from "./PerformingCoins";

export default function PortfolioMetadata({ coins }) {
  return (
    <Box
      sx={{
        width: {
          md: "38%",
          xs: "100%",
        },
      }}
    >
      <PerformingCoins coins={coins} />
    </Box>
  );
}
