import "./App.css";
import { Container, CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Web3 from "web3";
import { Web3ReactProvider } from "@web3-react/core";
import Router from "./components/Router";
import { useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

function App() {
  const getLibrary = (provider) => {
    return new Web3(provider);
  };

  /* const [activeHome, setActiveHome] = useState(0);

	const handleActiveHome = (index) => {
		setActiveHome(index);
	}; */

  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#20113E",
        light: "#553398",
        suppressedText: "#A4A4A4",
        contrastText: "#FFF",
        darker: "#382262",
        drawer: "#2A1750",
        navbarScroll: "#28164C",
      },
      secondary: {
        main: "#ffc50c",
        dark: "#9A7900",
        contrastText: "#fff",
      },
      misc: {
        error: "#ff4976",
        success: "#46E7A5",
        warning: "#E1D340",
      },
      background: {
        default: "#20113E",
        modal: "#171717",
      },
    },
    typography: {
      fontFamily: "Barlow",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        // lg: 1200,
        xl: 1200,
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/*<NavigationetActiveHome={handleActiveHome}  />*/}
          {/* <Container maxWidth="xl"> */}
          <Router /* activeHome={activeHome} */ />
          {/* </Container> */}
        </ThemeProvider>
      </Web3ReactProvider>
    </LocalizationProvider>
  );
}

export default App;
