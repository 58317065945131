import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

export default function EmptyPage({
    type
}) {
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 5
            }}
        >
            <Typography
                variant='button'
                sx={{
                    fontSize: {
                        md: 18
                    }
                }}
            >
                No {type} events found against currently applied filters
            </Typography>
        </Box>
    )
}
