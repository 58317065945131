import { Box, Typography } from '@mui/material'
import React from 'react'

export default function NoOrders() {
  return (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(80vh - 10rem)'
        }}
    >
        <Typography
            variant="h5"
        >
            You have no orders yet
        </Typography>
    </Box>
  )
}
