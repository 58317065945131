import { LinearProgress } from '@mui/material'
import React from 'react'

export default function LinearLoading({ display }) {
    return (
        display &&
        <LinearProgress
            color='secondary'
            variant='indeterminate'
        />
    )
}
