import axios from "axios";
import Endpoints from "./Endpoints";

function getAllEvents(body = {}, params = {}) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.post(Endpoints.EVENTS_BASE, body, { params });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getEvent(event_id = "") {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.EVENT_DETAILS, {
        params: {
          event_id,
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function login(account = "", params = {}) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.post(Endpoints.LOGIN, {
        user_address: account,
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getCoins() {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.COINS);
      console.log("Res data", response.data);
      const index = response.data.findIndex(
        (element) => element.crypto_id === "bittorrent-2"
      );
      if (index !== -1) {
        response.data.splice(index, 1);
      }
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getMyEvents(user_id) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.MY_EVENTS, {
        params: {
          user_id,
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function addEvent(data) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.post(Endpoints.CREATE_EVENT, data);
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function joinEvent(data) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.post(Endpoints.JOIN_EVENT, data);
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getTransactions(params) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.TRANSACTIONS, {
        params: {
          ...params,
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getPortfolio(params) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.PORTFOLIO, {
        params: {
          ...params,
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getVestibuleDetails(params) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.EVENTS_VESTIBULE_DETAILS, {
        params: {
          ...params,
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getVestibule(params) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.VESTIBULE_DETAILS, {
        params: {
          ...params,
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getLeaderboards(params) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.LEADERBOARDS, {
        params: {
          ...params,
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getBalances(params) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.BALANCE, {
        params: {
          ...params,
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function postBuy(data) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.post(Endpoints.BUY, data);
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function postSell(data) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.post(Endpoints.SELL, data);
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getCryptoBalance(params) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.CRYPTO_BALANCE, {
        params: { ...params },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function getWithdrawableEvents(user_id) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(Endpoints.WITHDRAWABLE_EVENTS, {
        params: { user_id },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function postWithdraw(data) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.post(Endpoints.WITHDRAW, data);
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

function sendTransaction(data) {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.post(Endpoints.SEND_TXN, data);
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

export default {
  getAllEvents,
  login,
  getEvent,
  getMyEvents,
  getCoins,
  addEvent,
  joinEvent,
  getTransactions,
  getPortfolio,
  getVestibuleDetails,
  getLeaderboards,
  getBalances,
  postBuy,
  postSell,
  getCryptoBalance,
  getWithdrawableEvents,
  postWithdraw,
  sendTransaction,
  getVestibule,
};
