import { Box, Stack, Typography, Avatar, Divider, Button, IconButton } from '@mui/material'
import StyledTextField from '../../CreateEventNew/StyledTextField'
import React, { useState, useEffect } from 'react'
import Base from '../Base'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Cancel } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import SilhoutteLoader from '../SilhoutteLoader'
import OneDirectionalLoader from '../OneDirectionalLoader'

const USDC_IMG = "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389"
const MAX_DECIMALS = 9
const MAX_RADIX = 6

export default function TransactionConfirmation({ onClose, open, data, type, loading, onConfirmation }) {
    const [internalData, setInternalData] = useState(data)
    /*const {
        usdPrice,
        quantity,
        symbol,
        image,
        current_price,
        asset_percent
    } = internalData */

    const [confirmed, setConfirmed] = useState(false)
    const [accepted, setAccepted] = useState(true)

    useEffect(() => {
        if (open) {
            if (internalData.current_price !== data.current_price) {
                setAccepted(false)
            }
        }
        else{
            setInternalData(data)
        }
    }, [data])

    useEffect(() => {
        if (accepted)
            setInternalData(data)
    }, [accepted])

    const handleClose = () => {
        if (!confirmed) {
            setConfirmed(false)
            onClose()
        }
    }

    const handleConfirmation = () => {
        setConfirmed(true)
    }

    const trim = (value = "") => {
        if (value.includes('e')) {
            value = Number(value).toFixed(MAX_DECIMALS)
        }
        if (value.includes('.')) {
            var decimals = value.substring(value.indexOf('.') + 1, value.length)
            decimals = decimals.slice(0, MAX_DECIMALS)
            let index
            for (let i = decimals.length - 1; i >= 0; i--) {
                if (decimals.charAt(i) !== '0') break;
                else
                    index = i
            }
            decimals = decimals.substring(0, index)
            value = value.substring(0, value.indexOf('.'))
            if (decimals.length > 0)
                value += '.' + decimals
        }
        return value
    }

    const CurrencySelector = ({ image, currency }) => (
        <Box
            sx={{
                p: 2,
            }}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={1}
                >
                    <Avatar
                        src={image}
                        sx={{
                            height: 30,
                            width: 30,
                        }}
                    />
                    <Stack>
                        <Typography
                            fontSize={20}
                            fontWeight={500}
                            color='white'
                        >
                            {currency}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )

    return (
        <Base
            onClose={handleClose}
            open={open}
        >
            <Stack
                spacing={2}
                sx={{
                    width: {
                        xs: '80vw',
                        md: '29rem'
                    }
                }}
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography
                        variant='button'
                        sx={{
                            fontSize: 22
                        }}
                    >
                        Confirm Transaction
                    </Typography>
                    {!confirmed && <IconButton
                        onClick={handleClose}
                    >
                        <Cancel />
                    </IconButton>}
                </Stack>
                <Stack
                    //alignItems='end'
                    justifyContent='center'
                    spacing={1}
                    position='relative'
                >
                    <StyledTextField
                        variant='outlined'
                        //label="YOU PAY"
                        fullWidth
                        value={internalData?.usdPrice}
                        type='number'
                        disabled
                        InputProps={{
                            endAdornment: <CurrencySelector
                                image={USDC_IMG}
                                currency={"USD"}
                                value={Number(internalData?.usdPrice).toLocaleString()}
                            />,
                            sx: {
                                pr: 0,
                                fontSize: {
                                    xs: 18,
                                    md: 22
                                },
                                background: "#1F1F1F",
                                borderRadius: 2,
                                border: '1px solid #80808050',
                                fontWeight: '500',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                }
                            }
                        }}
                        sx={{
                            "& label.Mui-focused": {
                                color: "none",
                            },
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "none",
                            },
                            "& .MuiOutlinedInput-root": {
                                "fieldset": {
                                    border: "none"
                                }
                            },
                        }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            zIndex: 999999999,
                            borderRadius: '50%',
                            background: "#1F1F1F",
                            border: theme => `5px solid ${theme.palette.background.modal}`,
                            left: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transform: 'translate(-50 %, -50 %)'
                        }}
                    >
                        <img
                            src='/assets/arrow.svg'
                            style={{
                                height: 25,
                                width: 25,
                                padding: "3px",
                                transform: type === 'Sell' && 'rotate(-180deg)'
                            }}
                        />
                    </Box>
                    <StyledTextField
                        variant='outlined'
                        //label="YOU RECEIVE"
                        placeholder='0.00'
                        value={internalData?.quantity}
                        fullWidth
                        disabled
                        type='number'
                        InputProps={{
                            endAdornment: <CurrencySelector
                                image={internalData?.image}
                                currency={internalData?.symbol?.toUpperCase()}
                                value={Number(internalData?.current_price).toLocaleString()}
                            />,
                            sx: {
                                pr: 0,
                                fontSize: {
                                    xs: 18,
                                    md: 20
                                },
                                fontWeight: '500',
                                borderRadius: 2,
                                background: "#1F1F1F",
                                border: '1px solid #80808050',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                }
                            }
                        }}
                        sx={{
                            "& label.Mui-focused": {
                                color: "none",
                            },
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "none",
                            },
                            "& .MuiOutlinedInput-root": {
                                "fieldset": {
                                    border: "none"
                                }
                            },
                        }}
                    />
                </Stack>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography
                        sx={{
                            color: "#fff",
                            pl: 2
                        }}
                        fontWeight={500}
                        fontSize={15}
                    //letterSpacing={1.01}
                    >
                        {type === 'Buy' ? `1 ${internalData?.symbol?.toUpperCase()} = ${internalData?.current_price} USD` : `1 USD = ${Number(1 / Number(internalData?.current_price)).toFixed(9)} ${internalData?.symbol?.toUpperCase()}`}
                    </Typography>
                    <Typography
                        textTransform='uppercase'
                        fontWeight={500}
                        fontSize={15}
                        sx={{
                            color: theme => theme.palette.misc.success,
                            pr: 2
                        }}
                        className='blink'
                    >
                        TXN Guaranteed
                    </Typography>
                </Stack>
                <Stack
                    sx={{
                        background: "#1F1F1F",
                        borderRadius: 2,
                        p: 2,
                        border: '1px solid #80808050'
                    }}
                    spacing={1}
                >
                    <Stack
                        spacing={0.5}
                    >
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                        >
                            <Typography
                                sx={{
                                    color: "#fff"
                                }}
                                fontWeight={500}
                                fontSize={15}
                            >
                                Expected Output
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#fff"
                                }}
                                fontWeight={500}
                                fontSize={15}
                            >
                                {type === 'Buy' ? `${internalData?.quantity} ${internalData?.symbol?.toUpperCase()}` : `${internalData?.usdPrice} USD`}
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                        >
                            <Typography
                                sx={{
                                    color: "#fff"
                                }}
                                fontWeight={500}
                                fontSize={15}
                            >
                                Assets Value Impact
                            </Typography>
                            <Typography
                                sx={{
                                    color: theme => internalData?.asset_percent < 0 ? theme.palette.misc.error : theme.palette.misc.success
                                }}
                                fontWeight={500}
                                fontSize={15}
                            >
                                {internalData?.asset_percent ? internalData?.asset_percent : 0}%
                            </Typography>
                        </Stack>
                    </Stack>

                    <Divider />
                    <Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                        >
                            <Typography
                                sx={{
                                    color: theme => theme.palette.primary.suppressedText
                                }}
                                fontWeight={500}
                                fontSize={15}
                                maxWidth='60%'
                            >
                                Minimum received after slippage
                            </Typography>
                            <Typography
                                sx={{
                                    color: theme => theme.palette.primary.suppressedText
                                }}
                                align='right'
                                fontWeight={500}
                                fontSize={15}
                            //maxWidth='20%'
                            >
                                {type === 'Buy' ? Number(Number(internalData?.quantity) - Number((0.5 / 100) * Number(internalData?.quantity))).toFixed(9) : Number(Number(internalData?.usdPrice) - Number((0.5 / 100) * Number(internalData?.usdPrice))).toFixed(9)}
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                        >
                            <Typography
                                sx={{
                                    color: theme => theme.palette.primary.suppressedText
                                }}
                                fontWeight={500}
                                fontSize={15}
                                maxWidth='60%'
                            >
                                (0.50%)
                            </Typography>
                            <Typography
                                sx={{
                                    color: theme => theme.palette.primary.suppressedText
                                }}
                                align='right'
                                fontWeight={500}
                                fontSize={15}
                            //maxWidth='20%'
                            >
                                {type === 'Buy' ? internalData?.symbol?.toUpperCase() : 'USD'}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Typography
                    sx={{
                        color: theme => theme.palette.primary.suppressedText
                    }}
                    fontWeight={500}
                    fontSize={15}
                    maxWidth='100%'
                >
                    <i>Please do not consider the displayed quantity to be accurate. However, you are guaranteed to receive atleast <b>{type === 'Buy' ? trim(Number(Number(internalData?.quantity) - Number((0.5 / 100) * Number(internalData?.quantity))).toFixed(MAX_DECIMALS)) : trim(Number(Number(internalData?.usdPrice) - Number((0.5 / 100) * Number(internalData?.usdPrice))).toFixed(MAX_DECIMALS))} {type === 'Buy' ? internalData?.symbol?.toUpperCase() : 'USD'}</b></i>.
                </Typography>
                {
                    !accepted && !confirmed &&
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{
                            borderRadius: 2,
                            background: '#2E2E2E',
                            py: 1.2,
                            px: 2,
                            border: '1px solid #80808050'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '500',
                                fontSize: {
                                    xs: 14,
                                    md: 16
                                }
                            }}
                        >
                            Prices updated to latest values.
                        </Typography>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: theme => theme.palette.primary.light,
                                "&:hover": {
                                    background: theme => theme.palette.primary.light + "aa"
                                },
                                borderRadius: 2,
                            }}
                            onClick={() => setAccepted(true)}
                            disableElevation
                        >
                            <Typography
                                fontWeight={500}
                                color='#fff'
                                sx={{
                                    fontSize: {
                                        xs: 14,
                                        md: 16
                                    }
                                }}
                            >
                                Accept
                            </Typography>
                        </Button>
                    </Stack>
                }
                <LoadingButton
                    sx={{
                        backgroundColor: theme => !confirmed ? theme.palette.primary.light : 'transparent',
                        "&:hover": {
                            background: theme => !confirmed ? theme.palette.primary.light + "aa" : 'transparent'
                        },
                        p: 2,
                        borderRadius: 2,
                        position: 'relative',
                        border: confirmed ? '1px solid #80808050' : 'none'
                    }}
                    //loading
                    fullWidth
                    variant='contained'
                    disableElevation
                    disabled={!accepted}
                    onClick={handleConfirmation}
                >
                    <Typography
                        fontSize={18}
                        fontWeight={500}
                        color='#fff'
                    >
                        {!confirmed ? `Confirm ${type?.charAt(0).toUpperCase() + type?.slice(1)}` : 'Processing'}
                    </Typography>
                    {confirmed && <OneDirectionalLoader
                        onAnimationEnd={onConfirmation}
                    />}
                </LoadingButton>
            </Stack>
        </Base>
    )
}
