import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  Box,
  Button,
  CardActionArea,
  Container,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SupportedWallets from "../Modals/Wallet/SupportedWallets";
import ResponsiveLogo from "./ResponsiveLogo";
import ResponsiveMenu from "./ResponsiveMenu";
import { connector } from "../../wallet/connector.wallet";
import Reducers from "../../API/Reducers";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import WalletConnecting from "../Modals/Wallet/WalletConnecting";
import WalletConnected from "../Modals/Wallet/WalletConnected";
import WalletUnsupportedChain from "../Modals/Wallet/WalletUnsupportedChain";
import WalletChainChanged from "../Modals/Wallet/WalletChainChanged";
import WalletDisconnecting from "../Modals/Wallet/WalletDisconnecting";
import WalletDisconnected from "../Modals/Wallet/WalletDisconnected";
import ResponsiveAccountDetails from "./ResponsiveAccountDetails";
import StorageKeys from "../../storage.keys";
import Routes from "./routes.json";
import jazzicon from "@metamask/jazzicon";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ERC20 from "../../dApp/erc20.js";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import "./style.css";
import Web3Utils from "../../utils/web3.utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../EventDetails/constants";
import { useBalance } from "../../hooks/web3.hooks";
import ResponsiveSocials from "./ResponsiveSocials";
import { useStorage } from "../../hooks/storage.hooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Navbar({ onTabChanged }) {
  const [modal, setModal] = useState(null);
  //const [balances, setBalance] = useState(null)
  const balances = useBalance();
  const { activate, account, active, deactivate, error } = useWeb3React();
  const [connecting, setConnecting] = useState(false);
  const unsupportedChainId = error instanceof UnsupportedChainIdError;

  const [userId, setStorage, removeStorage] = useStorage(StorageKeys.USER_ID);
  const [clickedIndex, setClickedIndex] = useState(0);

  const navigate = useNavigate();

  const changeChain = async () => {
    return await Web3Utils.changeChain();
  };

  const connect = async () => {
    try {
      setModal(<WalletConnecting open onClose={null} wallet={"metamask"} />);
      await activate(connector);
      setModal(
        <WalletConnected
          open
          onClose={() => setModal(null)}
          wallet={"metamask"}
        />
      );
    } catch (err) {
      //onError(err)
      console.log(err);
    }
  };

  const location = useLocation();
  //console.log(location.pathname)

  const disconnect = () => {
    deactivate();
    //sessionStorage.removeItem(StorageKeys.USER_ID);
    removeStorage(StorageKeys.USER_ID);
    setModal(
      <WalletDisconnected
        open
        redirect={location.pathname !== "/"}
        time={10000}
        onClose={() => (location.pathname === "/" ? setModal(null) : null)}
        wallet={"metamask"}
        onTimeout={() => {
          navigate("/");
          setModal(null);
        }}
      />
    );
    if (location.pathname === "/")
      setTimeout(() => {
        setModal(null);
      }, 2000);
  };

  useEffect(() => {
    if (active) {
      login();
      setStorage(StorageKeys.ACCOUNT, account);
      //sessionStorage.setItem(StorageKeys.ACCOUNT, account)
      localStorage.setItem("account", account);
      //setBalance(Number(Web3Utils.toEther(await Web3Utils.callSpecial(IERC20.abi, '0x0000000000000000000000000000000000001010', 'balanceOf', account))).toFixed(4) + " MATIC")
    }
  }, [active, account]);

  useEffect(async () => {
    if (userId) {
      setConnecting(true);
      await activate(connector);
      setConnecting(false);
    }
  }, []);

  const login = async () => {
    try {
      let response = await Reducers.login(account);
      if (response) {
        //sessionStorage.setItem(StorageKeys.USER_ID, response.user_id);
        setStorage(StorageKeys.USER_ID, response.user_id);
        //window.dispatchEvent(new Event('storage'))
        setTimeout(() => {
          setModal(null);
        }, 2000);
      }
    } catch (err) {
      //onError(err)
      console.log("ERROR: ", err);
    }
  };

  useEffect(() => {
    if (unsupportedChainId)
      setModal(
        <WalletUnsupportedChain
          open
          onClose={null}
          onDisconnect={disconnect}
          onChangeChain={changeChain}
        />
      );
    else setModal(null);
  }, [unsupportedChainId]);

  const avatarRef = useRef();
  const accountMenuRef = useRef();
  const accountMenuParentRef = useRef();

  useEffect(() => {
    const element = avatarRef.current;
    if (element && account) {
      const addr = account.slice(2, 10);
      const seed = parseInt(addr, 16);
      const icon = jazzicon(20, seed);
      if (element.firstChild) {
        element.removeChild(element.firstChild);
      }
      element.appendChild(icon);
    }
  }, [account, avatarRef.current, active]);

  const handleMenuItemClicked = (route = "", index) => {
    setClickedIndex(index);
    if (route.startsWith("http") || route.startsWith("https"))
      window.open(route, "_blank", "noreferrer noopener");
    else navigate(route);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(account);
    var copy_elem = document.getElementById("copy");
    var success_elem = document.getElementById("success");

    copy_elem.style.opacity = 0;
    success_elem.style.opacity = 1;

    setTimeout(() => {
      success_elem.style.opacity = 0;
      copy_elem.style.opacity = 1;
    }, 1000);
  };

  const handleWindowClick = (e) => {
    e.stopPropagation();
    //console.log(e.target.classList)
    if (
      !e.target?.classList.contains("disconnect-text") &&
      !e.target?.classList.contains("options") &&
      !e.target?.classList.contains("MuiSvgIcon-root") &&
      !e.target?.classList.contains("copy") &&
      !e.target?.classList.contains("disconnect") &&
      !e.target?.classList.contains("accordion-summary") &&
      !e.target?.classList.contains("accordion-details") &&
      !e.target?.classList.contains("accordion") &&
      !e.target?.classList.contains("balance") &&
      !e.target?.classList.contains("balances-root") &&
      !e.target?.classList.contains("balance-stack-root") &&
      !e.target?.classList.contains("accordion-text") &&
      !e.target?.classList.contains("MuiAccordionSummary-contentGutters") &&
      !e.target?.classList.contains("MuiAvatar-img")
    ) {
      accountMenuRef.current?.classList.remove("opacity");
      setTimeout(() => {
        if (accountMenuRef.current)
          accountMenuRef.current.classList.remove("show");
      }, 100);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleWindowClick);

    return () => window.removeEventListener("click", handleWindowClick);
  }, []);

  const handleAccountClicked = (e) => {
    if (accountMenuRef.current) {
      if (!accountMenuRef.current.classList.contains("show")) {
        accountMenuRef.current.classList.add("show");
        setTimeout(() => {
          if (accountMenuRef.current)
            accountMenuRef.current.classList.add("opacity");
        }, 100);
      } else {
        if (
          !e.target?.classList.contains("copy") &&
          !e.target?.classList.contains("options") &&
          !e.target?.classList.contains("MuiSvgIcon-root")
        ) {
          accountMenuRef.current.classList.remove("opacity");
          setTimeout(() => {
            if (accountMenuRef.current)
              accountMenuRef.current.classList.remove("show");
          }, 100);
        }
      }
    }
  };

  return (
    <>
      {modal}
      <AppBar
        sx={{
          boxShadow: trigger ? 10 : "none",
          borderBottom: "1px solid #442d76",
          background: (theme) =>
            trigger
              ? theme.palette.primary.navbarScroll
              : theme.palette.primary.main,
        }}
        enableColorOnDark
        position="fixed"
      >
        <Toolbar>
          {/* <Container maxWidth="xl"> */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <ResponsiveLogo />
            {!connecting ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <ResponsiveMenu
                  opts={[...Routes]}
                  onMenuItemClicked={handleMenuItemClicked}
                  menuIndex={clickedIndex}
                />
                {active && <ResponsiveAccountDetails network="mumbai" />}
                {!active ? (
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      /* letterSpacing: 1,
													fontSize: 16, */
                      background: (theme) =>
                        active && theme.palette.primary.light,
                    }}
                    className="create-event-btn"
                    onClick={() =>
                      setModal(
                        <SupportedWallets
                          open
                          onClose={() => setModal(null)}
                          onConnectionRequest={connect}
                        />
                      )
                    }
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: 16,
                      }}
                      align="center"
                      fontWeight={500}
                    >
                      Connect
                    </Typography>
                  </Button>
                ) : (
                  <div ref={accountMenuParentRef} className="disconnect-btn">
                    <Button
                      variant="contained"
                      onClick={handleAccountClicked}
                      disableElevation
                      sx={{
                        letterSpacing: 1,
                        fontSize: 16,
                        background: (theme) =>
                          theme.palette.primary.light + "90",
                      }}
                      className="disconnect"
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        {!avatarRef.current && (
                          <Skeleton
                            height={30}
                            width={30}
                            variant="circular"
                            animation="wave"
                          />
                        )}
                        <div
                          ref={avatarRef}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                          className="avatar-icon"
                        ></div>
                        <Typography
                          className="disconnect-text"
                          variant="subtitle1"
                          textTransform="none"
                          sx={{
                            fontSize: {
                              md: 16,
                              xs: 13,
                            },
                          }}
                          align="center"
                          fontWeight={500}
                        >
                          {account.substring(0, 6) +
                            "..." +
                            account.substring(
                              account.length - 4,
                              account.length
                            )}
                        </Typography>
                      </Stack>
                    </Button>
                    <Box ref={accountMenuRef} className="account-menu">
                      <Stack
                        divider={<Divider />}
                        sx={{
                          p: 2,
                        }}
                        spacing={1.5}
                      >
                        <Accordion className="accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="accordion-summary"
                          >
                            <Typography
                              //variant="caption"
                              className="accordion-text"
                              fontWeight={500}
                              /* sx={{
																	color: (theme) => theme.palette.primary.suppressedText
																}} */
                            >
                              Balances
                            </Typography>
                          </AccordionSummary>
                          <Divider />
                          <AccordionDetails className="accordion-details">
                            <Stack
                              spacing={1.5}
                              mt={1}
                              className="balances-root"
                              //className='options'
                            >
                              {balances ? (
                                balances.map(({ token, bal }) => (
                                  <Stack
                                    spacing={2}
                                    direction="row"
                                    alignItems="center"
                                    className="balance-stack-root"
                                  >
                                    <Avatar
                                      src={
                                        ERC20[
                                          process.env
                                            .REACT_APP_SUPPORTED_TESTNETS
                                        ]["assets"][token]["image"]
                                      }
                                      sx={{
                                        height: 30,
                                        width: 30,
                                        background: (theme) =>
                                          theme.palette.primary.light + "90",
                                        p: 0.2,
                                      }}
                                    />
                                    <Typography
                                      fontWeight="500"
                                      variant="body2"
                                      fontSize={16}
                                      className="balance"
                                      sx={{
                                        color: (theme) =>
                                          Number(bal.split("K")[0]) > 0
                                            ? "white"
                                            : theme.palette.misc.error,
                                      }}
                                    >
                                      {bal} {token.toUpperCase()}
                                    </Typography>
                                  </Stack>
                                ))
                              ) : (
                                <>
                                  {Object.keys(
                                    ERC20[
                                      process.env.REACT_APP_SUPPORTED_TESTNETS
                                    ]["assets"]
                                  ).map((_) => (
                                    <Stack
                                      spacing={2}
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <Skeleton
                                        variant="circular"
                                        height={30}
                                        width={30}
                                        animation="wave"
                                      />
                                      <Skeleton
                                        width={80}
                                        height={20}
                                        variant="text"
                                        animation="wave"
                                      />
                                    </Stack>
                                  ))}
                                </>
                              )}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                        {/* <Stack
														>
															<Typography
																variant="caption"
																sx={{
																	color: (theme) => theme.palette.primary.suppressedText
																}}
															>
																Balances
															</Typography>
															<Stack
																spacing={1.5}
																mt={1}
															//className='options'
															>
																{
																	balances ?
																		balances.map(({ token, bal }) => (
																			<Stack
																				spacing={2}
																				direction='row'
																				alignItems='center'
																			>
																				<Avatar
																					src={ERC20[process.env.REACT_APP_SUPPORTED_TESTNETS]['assets'][token]['image']}
																					sx={{
																						height: 30,
																						width: 30,
																						background: (theme) => theme.palette.primary.light + "90",
																						p: 0.2
																					}}
																				/>
																				<Typography
																					fontWeight='500'
																					variant='body2'
																					fontSize={16}
																					sx={{
																						color: theme => Number(bal) > 0 ? 'white' : theme.palette.misc.error
																					}}
																				>
																					{bal} {token.toUpperCase()}
																				</Typography>
																			</Stack>
																		))
																		:
																		<>
																			{
																				Object.keys(ERC20[process.env.REACT_APP_SUPPORTED_TESTNETS]['assets']).map(_ => (
																					<Stack
																						spacing={2}
																						direction='row'
																						alignItems='center'
																					>
																						<Skeleton
																							variant='circular'
																							height={30}
																							width={30}
																							animation='wave'
																						/>
																						<Skeleton
																							width={80}
																							height={20}
																							variant='text'
																							animation='wave'
																						/>
																					</Stack>
																				))
																			}
																		</>
																}
															</Stack>
														</Stack> */}
                        <Stack
                          //className='options'
                          spacing={2}
                          direction="row"
                          onClick={() =>
                            window.open(
                              `https://mumbai.polygonscan.com/address/${account}`,
                              "_blank",
                              "noreferrer noopener"
                            )
                          }
                          sx={{
                            "&:hover": {
                              "& .text": {
                                color: "white !important",
                              },
                              "& .icon": {
                                color: "white !important",
                              },
                            },
                            transition: "0.3s ease all",
                          }}
                        >
                          <Typography
                            fontWeight="500"
                            className="text"
                            fontSize={14}
                            sx={{
                              color: (theme) =>
                                theme.palette.primary.suppressedText,
                            }}
                          >
                            View on explorer
                          </Typography>
                          <OpenInNewIcon
                            className="icon"
                            fontSize="small"
                            sx={{
                              color: (theme) =>
                                theme.palette.primary.suppressedText,
                            }}
                          />
                        </Stack>
                        <Stack
                          spacing={2}
                          direction="row"
                          sx={{
                            "&:hover": {
                              "& .text": {
                                color: "white !important",
                              },
                              "& .icon": {
                                color: "white !important",
                              },
                            },
                          }}
                          onClick={handleCopyAddress}
                          className="options"
                        >
                          <Typography
                            fontWeight="500"
                            className="text copy"
                            fontSize={14}
                            sx={{
                              color: (theme) =>
                                theme.palette.primary.suppressedText,
                            }}
                          >
                            Copy Address
                          </Typography>
                          <Box
                            sx={{
                              position: "relative",
                            }}
                          >
                            <Box
                              id="copy"
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                opacity: 1,
                                transition: "0.3s ease all",
                              }}
                            >
                              <ContentCopyIcon
                                className="icon copy"
                                fontSize="small"
                                sx={{
                                  color: (theme) =>
                                    theme.palette.primary.suppressedText,
                                }}
                              />
                            </Box>
                            <Box
                              id="success"
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                opacity: 0,
                                transition: "0.3s ease all",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                fontSize="small"
                                sx={{
                                  color: (theme) => theme.palette.misc.success,
                                }}
                              />
                            </Box>
                          </Box>
                        </Stack>
                        <Stack
                          spacing={2}
                          //className='options'
                          direction="row"
                          onClick={() =>
                            window.open(
                              `https://bricksprotocol.notion.site/Testnet-Starter-Guide-82eba71ccdcb45358f9b5905f233c8a9`,
                              "_blank",
                              "noreferrer noopener"
                            )
                          }
                          sx={{
                            "&:hover": {
                              "& .text": {
                                color: "white !important",
                              },
                              "& .icon": {
                                color: "white !important",
                              },
                            },
                            transition: "0.3s ease all",
                          }}
                        >
                          <Typography
                            fontWeight="500"
                            className="text"
                            fontSize={14}
                            sx={{
                              color: (theme) =>
                                theme.palette.primary.suppressedText,
                            }}
                          >
                            TestNet Starter Guide
                          </Typography>
                          <OpenInNewIcon
                            className="icon"
                            fontSize="small"
                            sx={{
                              color: (theme) =>
                                theme.palette.primary.suppressedText,
                            }}
                          />
                        </Stack>
                        <Stack
                          spacing={2}
                          //className='options'
                          direction="row"
                          onClick={() =>
                            window.open(
                              `https://mumbaifaucet.com/`,
                              "_blank",
                              "noreferrer noopener"
                            )
                          }
                          sx={{
                            "&:hover": {
                              "& .text": {
                                color: "white !important",
                              },
                              "& .icon": {
                                color: "white !important",
                              },
                            },
                            transition: "0.3s ease all",
                          }}
                        >
                          <Typography
                            fontWeight="500"
                            className="text"
                            fontSize={14}
                            sx={{
                              color: (theme) =>
                                theme.palette.primary.suppressedText,
                            }}
                          >
                            Get MATIC
                          </Typography>
                          <OpenInNewIcon
                            className="icon"
                            fontSize="small"
                            sx={{
                              color: (theme) =>
                                theme.palette.primary.suppressedText,
                            }}
                          />
                        </Stack>
                        <Stack
                          spacing={2}
                          //className='options'
                          direction="row"
                          onClick={() =>
                            window.open(
                              `https://app.aave.com/faucet/`,
                              "_blank",
                              "noreferrer noopener"
                            )
                          }
                          sx={{
                            "&:hover": {
                              "& .text": {
                                color: "white !important",
                              },
                              "& .icon": {
                                color: "white !important",
                              },
                            },
                            transition: "0.3s ease all",
                          }}
                        >
                          <Typography
                            fontWeight="500"
                            className="text"
                            fontSize={14}
                            sx={{
                              color: (theme) =>
                                theme.palette.primary.suppressedText,
                            }}
                          >
                            Get Testnet Assets
                          </Typography>
                          <OpenInNewIcon
                            className="icon"
                            fontSize="small"
                            sx={{
                              color: (theme) =>
                                theme.palette.primary.suppressedText,
                            }}
                          />
                        </Stack>
                        <Button
                          variant="contained"
                          disableElevation
                          sx={{
                            background: (theme) =>
                              active && theme.palette.primary.light,
                          }}
                          onClick={disconnect}
                        >
                          Disconnect
                        </Button>
                      </Stack>
                    </Box>
                  </div>
                )}
                <ResponsiveSocials />
              </Stack>
            ) : (
              <Skeleton
                variant="text"
                animation="wave"
                width={180}
                height={50}
              />
            )}
          </Stack>
          {/* </Container> */}
        </Toolbar>
      </AppBar>
    </>
  );
}
