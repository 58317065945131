import { Avatar, Box, Button, Drawer, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TradeChart from "./Chart";

function MobileUI({
  selected,
  toggleDrawer,
  toggleSideDrawer,
  setDrawerType,
  setLoading,
  loading,
}) {
  return (
    <Box
      sx={{
        mt: 3,
        display: {
          xs: "flex",
          xl: "none",
        },
        px: 1,
        opacity: loading ? 0.2 : 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <TradeChart
          data={selected}
          height={"calc(100vh - 260px)"}
          setLoading={setLoading}
        />
        <Stack
          sx={{
            mt: 2,
          }}
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Button
            sx={{
              backgroundColor: (theme) => theme.palette.misc.success + 90,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.misc.success,
              },
              width: "45%",
            }}
            onClick={() => {
              setDrawerType("buy");
              toggleDrawer();
            }}

            // fullWidth
          >
            <Typography sx={{ fontWeight: "500", color: "white" }}>
              BUY
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: (theme) => theme.palette.misc.error + 90,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.misc.error,
              },
              width: "45%",
            }}
            onClick={() => {
              setDrawerType("sell");
              toggleDrawer();
            }}
            // fullWidth
          >
            <Typography sx={{ fontWeight: "500", color: "white" }}>
              SELL
            </Typography>
          </Button>
          <Avatar
            src={selected?.crypto_image}
            sx={{
              height: 45,
              width: 45,
              cursor: "pointer",
              p: 0.8,
              backgroundColor: (theme) => theme.palette.primary.darker,
            }}
            onClick={() => {
              toggleSideDrawer();
              setLoading(false);
            }}
          />
          {/* <img
						src={selected?.crypto_image}
						style={{
							height: 40,
							width: 40,
							cursor: "pointer",
						}}
					/> */}
        </Stack>
      </Box>
    </Box>
  );
}

export default MobileUI;
