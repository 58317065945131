import * as IPFS from "ipfs-http-client";

const {
  REACT_APP_IPFS_HOST: IPFS_HOST,
  REACT_APP_IPFS_PORT: IPFS_PORT,
  REACT_APP_IPFS_PROTOCOL: IPFS_PROTOCOL,
  REACT_APP_IPFS_PROJECT_ID: IPFS_PROJECT_ID,
  REACT_APP_IPFS_SECRET: IPFS_SECRET,
} = process.env;
const auth =
  "Basic " +
  Buffer.from(IPFS_PROJECT_ID + ":" + IPFS_SECRET).toString("base64");

const ipfs = IPFS.create({
  host: IPFS_HOST,
  port: IPFS_PORT,
  protocol: IPFS_PROTOCOL,
  headers: {
    authorization: auth,
  },
});

export default ipfs;
