import React, { useEffect, useState } from "react";
import Base from "../../Modals/Base";

import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

export default function TimeExpired({ open, onClose, time, onTimeout }) {
  const [progress, setProgress] = useState(time / 1000);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (progress === 0) {
        clearTimeout(timeout);
        onTimeout();
      } else {
        setProgress(progress - 1);
      }
    }, 1000);
  }, [progress]);

  return (
    <Base open={open} onClose={onClose}>
      <Stack
        // spacing={2}
        sx={{
          // minHeight: 500,
          width: 270,
        }}
        // justifyContent="space-between"
      >
        <Typography
          variant="button"
          sx={{
            fontSize: 22,
          }}
        >
          Tournament Completed
        </Typography>
        <Stack spacing={2}>
          <Typography variant="body2" color="GrayText">
            Since this event has ended so you won't be able to trade and we have
            to redirect you back home. This is necessary to maintain security
            and unauthorized access.
          </Typography>
          <Stack direction="row" spacing={2}>
            <Typography>
              You will be redirected automatically in {time / 1000}s.
            </Typography>
            <div style={{ position: "relative" }}>
              <span
                style={{
                  position: "absolute",
                  top: "11px",
                  left: "13px",
                  right: 0,
                  bottom: 0,
                  fontSize: 13,
                }}
              >
                {progress}s
              </span>
              <CircularProgress
                variant="determinate"
                value={progress * 10}
                color="secondary"
              />
            </div>
          </Stack>
          <Divider>OR</Divider>
          <Button
            onClick={onTimeout}
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              background: (theme) => theme.palette.primary.light,
            }}
          >
            Redirect
          </Button>
        </Stack>
      </Stack>
    </Base>
  );
}
