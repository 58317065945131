import React from 'react'
import { motion } from 'framer-motion'

export default function HoverUp({ children, onClick }) {
    return (
        <motion.div
            whileHover={{
                scale: 1.01,
                cursor: 'pointer'
            }}
            transition={{
                duration: 0.2
            }}
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1
            }}
            onClick={onClick}
        >
            {children}
        </motion.div>
    )
}
