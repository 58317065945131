import moment from "moment"

function unixToDate(unix = 1234){
    return new Date(unix * 1000)
}

function toUnix(date = '') {
    return Math.floor(new Date(date).getTime() / 1000)
}

function format(date){
    return moment(date).format('DD/MM/YYYY')
}

function formatTime(date){
    return moment(date).format('hh:mm A')
}

function formatDateTime(date){
    return format(date) + " " + formatTime(date)
}

export default {toUnix, unixToDate, format, formatTime, formatDateTime}