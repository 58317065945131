function trim(num = "") {
    let i
    for (i = num.length - 1; i >= 0; i--) {
        if (num.charAt(i) !== '0')
            break;
    }
    let output = num.substring(0, i + 1);
    if (output.charAt(output.length - 1) === "."){
        output = output.substring(0, output.length - 1)
    }
    return output
}

function convertToK(value){
    if(Math.floor(value / 1000) > 0){
        return (value / 1000).toFixed(2) + "K"
    }

    return value
}

export { trim, convertToK }