import { Box, Button, Stack } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
// import Reducers from "../../API/Reducers";
import Details from "./EventDetails";
import "./index.css";
import { Close } from "@mui/icons-material";
import ResponsiveLoading from "../Events/ResponsiveLoading";
import { useWindowDimensions } from "./constants";
import { useNavigate } from "react-router-dom";
import Contracts from "../../functions/contract.functions";
import { useWeb3React } from "@web3-react/core";

function EventDetails({ toggleDrawer, event, handleJoinClicked }) {
	const { width, height } = useWindowDimensions();

	const navigate = useNavigate()

	const { active } = useWeb3React()

	const handleLeaderboardsClicked = () => {
		navigate(`/leaderboards/${event.event_id}`)
	}

	return (
		<Box
			sx={{
				height: width < 400 ? "95vh" : "100vh",
				width: width < 400 ? width : "40vw",
				maxWidth: "500px",
				minWidth: width < 400 ? width : "390px",
				background: (theme) => theme.palette.primary.drawer /* + "90" */,
				p: 1,
				paddingInline: 2,
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				overflowX: "hidden",
			}}
		>
			<Close
				onClick={toggleDrawer}
				sx={{ position: "absolute", cursor: "pointer" }}
			/>
			<Details event={event} toggleDrawer={toggleDrawer} />
			<Stack
				direction='row'
				spacing={2}
				sx={{
					mb: 2,
				}}
			>
				{
					(event.status === 'ongoing' || event.status === 'completed') && <Button
						variant="contained"
						disableElevation
						sx={{
							letterSpacing: 1,
							fontSize: 16,
							background: (theme) => theme.palette.primary.dark + "90",
						}}
						fullWidth
						onClick={handleLeaderboardsClicked}
					>
						Leaderboards
					</Button>
				}
				{
					active && event.status != "completed" && !(event.status === 'scheduled' && event.is_joined) &&
					<Button
						variant="contained"
						disableElevation
						sx={{
							letterSpacing: 1,
							fontSize: 16,
							background: (theme) => theme.palette.primary.dark + "90",
						}}
						fullWidth
						onClick={() => handleJoinClicked(event)}
					>
						{!event.is_joined ? 'Join Event' : event.status === 'ongoing' ? 'Go to Event' : null}
					</Button>
				}
			</Stack>
		</Box>
	);
}

export default EventDetails;
