import React, { useEffect, useRef, useState } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import { Box, Stack, Typography } from "@mui/material";
import Header from "./Header";
import axios from "axios";

export default function TradeChart({ data, height, setLoading }) {
  const chartContainerRef = useRef();
  const chart = useRef();
  const resizeObserver = useRef();
  const [headerText, setHeaderText] = useState();

  function getData() {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await axios.get(
          `${process.env.REACT_APP_COIN_GECKO_BASE_URL}/coins/${data.crypto_id}/market_chart?vs_currency=usd&days=365&interval=monthly`
        );
        var response2 = await axios.get(
          `${process.env.REACT_APP_COIN_GECKO_BASE_URL}/coins/${data.crypto_id}/ohlc?vs_currency=usd&days=365`
        );
        let volumes = [
          ...response.data.total_volumes.map((i) => ({
            time: new Date(i[0]).toDateString(),
            value: Number(i[1]),
          })),
        ];

        console.log("Volumes-new ,", volumes);
        let ohlc = [
          ...response2.data.map((i) => ({
            time: new Date(i[0]).toDateString(),
            open: Number(i[1]),
            high: Number(i[2]),
            low: Number(i[3]),
            close: Number(i[4]),
          })),
        ];

        console.log("ohlc-new", ohlc);
        resolve({ volumes, ohlc });
      } catch (err) {
        reject(err);
      }
    });
  }

  useEffect(async () => {
    while (
      chartContainerRef &&
      chartContainerRef.current &&
      chartContainerRef.current.hasChildNodes()
    )
      chartContainerRef.current.removeChild(
        chartContainerRef.current.firstChild
      );
    var chartData = await getData();
    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: "100%", //"300px", //chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: "#38226280",
        textColor: "rgba(255, 255, 255, 0.9)",
      },
      grid: {
        vertLines: {
          color: "#334158",
        },
        horzLines: {
          color: "#334158",
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      priceScale: {
        borderColor: "#485c7b",
      },
      timeScale: {
        borderColor: "#485c7b",
      },
      priceFormat: {
        type: "price",
        precision: 8,
        minMove: 0.00000001,
      },
    });

    const candleSeries = chart.current.addCandlestickSeries({
      upColor: "#4bffb5",
      downColor: "#ff4976",
      borderDownColor: "#ff4976",
      borderUpColor: "#4bffb5",
      wickDownColor: "#838ca1",
      wickUpColor: "#838ca1",
    });

    candleSeries.applyOptions({
      priceFormat: {
        type: "price",
        precision: 6,
        minMove: 0.000001,
      },
    });

    const volumeSeries = chart.current.addHistogramSeries({
      color: "#0005",
      lineWidth: 2,
      priceFormat: {
        type: "volume",
      },
      overlay: true,
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    });

    candleSeries.setData(chartData.ohlc);
    volumeSeries.setData(chartData.volumes);

    chart.current.subscribeCrosshairMove((param) => {
      if (param.time) {
        const price = param.seriesPrices.get(candleSeries);
        setHeaderText(price);
      } else {
        setHeaderText();
      }
    });

    // Resize chart on container resizes.
    //useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      chart.current.applyOptions({ width, height });
      setTimeout(() => {
        chart.current.timeScale().fitContent();
      }, 0);
    });

    resizeObserver.current.observe(chartContainerRef.current);

    setLoading(false);

    return () => resizeObserver.current.disconnect();
  }, [data]);

  return (
    <Box sx={{ position: "relative", height: height }}>
      <Header data={data} headerText={headerText} />
      <div
        ref={chartContainerRef}
        className="chart-container"
        style={{ height: "100%" }}
      />
    </Box>
  );
}
