import { Box, Grid, Stack, Typography, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "../EventDetails/constants";

function MobileCryptoInfo({
  data,
  toggleDrawer,
  setLoading,
  setCryptoInfo,
  cryptoData,
  setDrawerType,
}) {
  // const [loading, setLoading] = useState(true);
  const [crypto, setCrypto] = useState();
  const { width, height } = useWindowDimensions();
  useEffect(() => {
    // setId(data && data.crypto_id ? data.crypto_id : "bitcoin");
    const fetchCrypto = async () => {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_COIN_GECKO_BASE_URL}/api/v3/coins/${data.crypto_id}`
      );
      console.log("mob-info", res);
      setCrypto(res.data);
      setCryptoInfo(res.data);
      //setLoading(false);
    };

    if (data?.crypto_id) fetchCrypto();
  }, [data]);

  useEffect(() => {
    setCrypto({
      ...crypto,
      crypto_current_price: cryptoData?.crypto_current_price,
      crypto_price_change_percent_24h:
        cryptoData?.crypto_price_change_percent_24h,
    });
  }, [cryptoData]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #553398",
          width: "100%",
          height: "52vh",
          background: {
            xs: "#0008",
            lg: "none",
          },
        }}
      >
        {/* {loading ? (
					<Typography variant="button" sx={{ fontSize: 24 }}>
						Loading
					</Typography>
				) : ( */}
        <>
          {crypto &&
          crypto.name &&
          crypto?.crypto_current_price &&
          crypto.market_data.price_change_24h_in_currency.usd &&
          crypto?.crypto_price_change_percent_24h ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: 2,
                  py: 1,
                }}
              >
                <img
                  src={
                    crypto && crypto.image && crypto.image.large
                      ? crypto.image.large
                      : "unknown"
                  }
                  style={{ height: 30, width: 30 }}
                />
                <Typography variant="button" sx={{ ml: 1, fontSize: 16 }}>
                  {crypto && crypto.symbol ? crypto.symbol : "unknown  "} USD
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  px: 2,
                }}
              >
                <Typography sx={{ opacity: 0.5 }}>
                  {crypto && crypto.name ? crypto.name : "unknown"} / U.S.
                  Dollar
                </Typography>
                <Typography
                  sx={{ fontSize: 26, fontWeight: "bold", letterSpacing: 1 }}
                >
                  {crypto?.crypto_current_price
                    ? Number(Number(crypto?.crypto_current_price).toFixed(8))
                    : "unknown"}{" "}
                  <span style={{ fontSize: 12, opacity: 0.5 }}>USD</span>
                </Typography>
                <Typography
                  variant="button"
                  sx={{ fontSize: 16, letterSpacing: 1 }}
                >
                  {crypto?.market_data?.price_change_24h_in_currency?.usd
                    ? Number(
                        Number(
                          crypto.market_data.price_change_24h_in_currency.usd
                        ).toFixed(8)
                      )
                    : "unknown"}{" "}
                  <span style={{ opacity: 0.5, marginLeft: 5 }}>
                    (
                    {crypto?.crypto_price_change_percent_24h
                      ? `${Number(
                          crypto?.crypto_price_change_percent_24h
                        ).toFixed(2)}%`
                      : "unknown"}
                    )
                  </span>
                </Typography>
                <Typography sx={{ mt: 2 }}>Key Stats</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ opacity: 0.5 }} variant="body2">
                    Volume
                  </Typography>
                  <Typography sx={{}} variant="body2">
                    {crypto &&
                    crypto.market_data &&
                    crypto.market_data.total_volume &&
                    crypto.market_data.total_volume.usd
                      ? Number(
                          crypto.market_data.total_volume.usd
                        ).toLocaleString()
                      : "unknown"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ opacity: 0.5 }} variant="body2">
                    Market Cap
                  </Typography>
                  <Typography sx={{}} variant="body2">
                    {crypto &&
                    crypto.market_data &&
                    crypto.market_data.market_cap &&
                    crypto.market_data.market_cap.usd
                      ? Number(
                          crypto.market_data.market_cap.usd
                        ).toLocaleString()
                      : "unknown"}
                  </Typography>
                </Box>
                {width >= 1200 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "47%",
                        height: 40,
                        borderRadius: 1,
                        cursor: "pointer",
                        backgroundColor: (theme) =>
                          theme.palette.misc.success + 90,
                      }}
                      onClick={() => {
                        setDrawerType("buy");
                        toggleDrawer();
                      }}
                    >
                      <Typography sx={{ fontWeight: "500" }}>BUY</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "47%",
                        height: 40,
                        borderRadius: 1,
                        cursor: "pointer",
                        backgroundColor: (theme) =>
                          theme.palette.misc.error + 90,
                      }}
                      onClick={() => {
                        setDrawerType("sell");
                        toggleDrawer();
                      }}
                    >
                      <Typography sx={{ fontWeight: "500" }}>SELL</Typography>
                    </Box>
                  </Box>
                )}
                <Typography sx={{ mt: 2 }}>Performance</Typography>
                <Grid container spacing={1} marginTop={0.2} marginBottom={1}>
                  <Grid item xs={12 / 3}>
                    <Stack
                      sx={{
                        alignItems: "center",
                        borderRadius: 1,
                        flexDirection: "column",
                        p: 1,
                        backgroundColor: (theme) =>
                          crypto?.market_data
                            ?.price_change_percentage_1h_in_currency?.usd < 0
                            ? theme.palette.misc.error + 30
                            : theme.palette.misc.success + 30,
                      }}
                    >
                      <Typography
                        variant="button"
                        sx={{
                          color: (theme) =>
                            crypto?.market_data
                              ?.price_change_percentage_1h_in_currency?.usd < 0
                              ? theme.palette.misc.error
                              : theme.palette.misc.success,
                        }}
                      >
                        {`${Number(
                          crypto?.market_data
                            ?.price_change_percentage_1h_in_currency?.usd
                        ).toFixed(2)}%`}
                      </Typography>
                      <Typography variant="body2">1H</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12 / 3}>
                    <Stack
                      sx={{
                        alignItems: "center",
                        borderRadius: 1,
                        flexDirection: "column",
                        p: 1,
                        backgroundColor: (theme) =>
                          crypto?.market_data?.price_change_percentage_7d < 0
                            ? theme.palette.misc.error + 30
                            : theme.palette.misc.success + 30,
                      }}
                    >
                      <Typography
                        variant="button"
                        sx={{
                          color: (theme) =>
                            crypto?.market_data?.price_change_percentage_7d < 0
                              ? theme.palette.misc.error
                              : theme.palette.misc.success,
                        }}
                      >
                        {`${Number(
                          crypto?.market_data?.price_change_percentage_7d
                        ).toFixed(2)}%`}
                      </Typography>
                      <Typography variant="body2">1W</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12 / 3}>
                    <Stack
                      sx={{
                        alignItems: "center",
                        borderRadius: 1,
                        flexDirection: "column",
                        p: 1,
                        backgroundColor: (theme) =>
                          crypto?.market_data?.price_change_percentage_14d < 0
                            ? theme.palette.misc.error + 30
                            : theme.palette.misc.success + 30,
                      }}
                    >
                      <Typography
                        variant="button"
                        sx={{
                          color: (theme) =>
                            crypto?.market_data?.price_change_percentage_14d < 0
                              ? theme.palette.misc.error
                              : theme.palette.misc.success,
                        }}
                      >
                        {`${Number(
                          crypto?.market_data?.price_change_percentage_14d
                        ).toFixed(2)}%`}
                      </Typography>
                      <Typography variant="body2">2W</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12 / 3}>
                    <Stack
                      sx={{
                        alignItems: "center",
                        borderRadius: 1,
                        flexDirection: "column",
                        p: 1,
                        backgroundColor: (theme) =>
                          crypto?.market_data?.price_change_percentage_30d < 0
                            ? theme.palette.misc.error + 30
                            : theme.palette.misc.success + 30,
                      }}
                    >
                      <Typography
                        variant="button"
                        sx={{
                          color: (theme) =>
                            crypto?.market_data?.price_change_percentage_30d < 0
                              ? theme.palette.misc.error
                              : theme.palette.misc.success,
                        }}
                      >
                        {`${Number(
                          crypto?.market_data?.price_change_percentage_30d
                        ).toFixed(2)}%`}
                      </Typography>
                      <Typography variant="body2">1M</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12 / 3}>
                    <Stack
                      sx={{
                        alignItems: "center",
                        borderRadius: 1,
                        flexDirection: "column",
                        p: 1,
                        backgroundColor: (theme) =>
                          crypto?.market_data?.price_change_percentage_60d < 0
                            ? theme.palette.misc.error + 30
                            : theme.palette.misc.success + 30,
                      }}
                    >
                      <Typography
                        variant="button"
                        sx={{
                          color: (theme) =>
                            crypto?.market_data?.price_change_percentage_60d < 0
                              ? theme.palette.misc.error
                              : theme.palette.misc.success,
                        }}
                      >
                        {`${Number(
                          crypto?.market_data?.price_change_percentage_60d
                        ).toFixed(2)}%`}
                      </Typography>
                      <Typography variant="body2">2M</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12 / 3}>
                    <Stack
                      sx={{
                        alignItems: "center",
                        borderRadius: 1,
                        flexDirection: "column",
                        p: 1,
                        backgroundColor: (theme) =>
                          crypto?.market_data?.price_change_percentage_1y < 0
                            ? theme.palette.misc.error + 30
                            : theme.palette.misc.success + 30,
                      }}
                    >
                      <Typography
                        variant="button"
                        sx={{
                          color: (theme) =>
                            crypto?.market_data?.price_change_percentage_1y < 0
                              ? theme.palette.misc.error
                              : theme.palette.misc.success,
                        }}
                      >
                        {`${Number(
                          crypto?.market_data?.price_change_percentage_1y
                        ).toFixed(2)}%`}
                      </Typography>
                      <Typography variant="body2">1Y</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress thickness={8} size={30} color="secondary" />
            </Box>
          )}
        </>
      </Box>
    </>
  );
}

export default MobileCryptoInfo;
