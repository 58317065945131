import React, { useState } from "react";
import { Box, Stack, Typography, CardActionArea, Divider, Paper } from "@mui/material";

export default function Tabs({ onTabsChanged, opts, tab }) {
    const menu = opts;

    const [current, setCurrent] = useState(tab);

    const handleTabChange = (index) => {
        setCurrent(index);
        onTabsChanged(index);
    };

    return (
        <Box
            sx={{
                width: "100%",
            }}
        >
            <Box>
                <Stack
                    direction="row"
                    alignItems="center"
                    width='100%'
                    sx={{
                        justifyContent: 'center',
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`
                    }}
                    divider={<Divider orientation="vertical" flexItem />}
                >
                    {menu.map((item, index) => (
                        <Stack
                            key={index}
                            direction='row'
                            sx={{
                                width: "100%",
                                p: 3,
                                "&:hover": {
                                    cursor: "pointer",
                                    background: (theme) => index === current ? theme.palette.secondary.main : theme.palette.secondary.dark + "30"
                                },
                                background:
                                    index === current
                                        ? (theme) => theme.palette.secondary.main
                                        : "none",
                                borderTopLeftRadius: index === 0 ? 10 : 0,
                                borderTopRightRadius: index === 1 ? 10 : 0,
                                transition: '0.3s ease all'
                            }}
                            alignItems='center'
                            justifyContent='center'
                            onClick={() => handleTabChange(index)}
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                color="#fff"
                                align='center'
                                sx={{
                                    fontSize: {
                                        xs: 16,
                                        md: 18,
                                    },
                                    color: index !== current ? 'white' : 'black',
                                }}
                            >
                                {item.toUpperCase()}
                            </Typography>
                            <Box
                                sx={{
                                    position: 'relative',
                                    ml: 1
                                }}
                            >
                                <Paper
                                    sx={{
                                        px: 1,
                                        py: 0.3,
                                        background: theme => theme.palette.primary.darker,
                                        position: 'absolute',
                                        bottom: '50%',
                                        left: 0,
                                    }}
                                    elevation={0}
                                >
                                    <Typography
                                        fontSize={11}
                                        fontWeight={500}
                                        align='center'
                                        color="white"
                                    >
                                        MARKET
                                    </Typography>
                                </Paper>
                            </Box>
                        </Stack>
                    ))}
                </Stack>
            </Box>
        </Box>
    );
}
