import { Box } from "@mui/material";
import Tabs from "./Tabs";
import React from "react";

export default function Base({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 600,
          height: "100%",
          backgroundColor: "#171717",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
