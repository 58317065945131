import { client as web3, CreateTournamentFactory, account } from "../dApp/web3";
import * as Web3 from "web3";
import Contracts from "../functions/contract.functions";
import ERC20 from "../dApp/erc20";
import axios from "axios";

function call(abi, fn = "", ...args) {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = new web3.eth.Contract(
        abiSelector(abi),
        Contracts.getLatestFactoryContractAddress()
      );
      resolve(await contract.methods[fn](...args).call());
    } catch (err) {
      reject(err);
    }
  });
}

function callSpecial(abi, address = "", options = {}, fn = "", ...args) {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = new web3.eth.Contract(abiSelector(abi), address);
      resolve(await contract.methods[fn](...args).call({ ...options }));
    } catch (err) {
      reject(err);
    }
  });
}

function callVar(abi, vn = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = new web3.eth.Contract(
        abiSelector(abi),
        Contracts.getLatestFactoryContractAddress()
      );
      resolve(await contract.methods[vn].call().call());
    } catch (err) {
      reject(err);
    }
  });
}

function callVarSpecial(abi, address = "", vn = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = new web3.eth.Contract(abiSelector(abi), address);
      resolve(await contract.methods[vn].call().call());
    } catch (err) {
      reject(err);
    }
  });
}

function send(abi, fn = "", ...args) {
  return new Promise(async (resolve, reject) => {
    try {
      const gasStationValue = await getFromGasStation();

      const maxPriorityFeePerGas = web3.utils.toWei(
        (Math.floor(gasStationValue.maxPriorityFee) + 1).toString(),
        "Gwei"
      );
      const maxFeePerGas = web3.utils.toWei(
        (Math.floor(gasStationValue.maxFee) + 1).toString(),
        "Gwei"
      );

      const contract = new web3.eth.Contract(
        abiSelector(abi),
        Contracts.getLatestFactoryContractAddress()
      );
      resolve(
        await contract.methods[fn](...args).send({
          from: account,
          maxPriorityFeePerGas,
          // maxFeePerGas,
        })
      );
    } catch (err) {
      reject(err);
    }
  });
}

function sendSpecial(
  abi,
  address = "",
  fn = "",
  options = {},
  multiplierFactor,
  ...args
) {
  return new Promise(async (resolve, reject) => {
    try {
      const gasStationValue = await getFromGasStation();

      const maxPriorityFeePerGas = web3.utils.toWei(
        (
          Math.floor(multiplierFactor * gasStationValue.maxPriorityFee) + 1
        ).toString(),
        "Gwei"
      );
      const maxFeePerGas = web3.utils.toWei(
        (Math.floor(multiplierFactor * gasStationValue.maxFee) + 1).toString(),
        "Gwei"
      );

      const contract = new web3.eth.Contract(abiSelector(abi), address);
      resolve(
        await contract.methods[fn](...args).send({
          from: account,
          maxPriorityFeePerGas,
          // maxFeePerGas,
          ...options,
        })
      );
    } catch (err) {
      reject(err);
    }
  });
}

function getFromGasStation() {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await axios.get(
        "https://gasstation-mumbai.matic.today/v2"
      );
      resolve(response.data.fast);
    } catch (err) {
      reject(err);
    }
  });
}

function toWei(ether = "") {
  return Web3.utils.toWei(ether, "ether");
}

function toEther(wei = "", asset) {
  if (asset) {
    const asset_details =
      ERC20[process.env.REACT_APP_SUPPORTED_TESTNETS]["assets"][asset];
    return asset_details.toEther(wei.toString());
  }
  return Web3.utils.fromWei(wei, "ether");
}

function getBalance() {
  return new Promise(async (resolve, reject) => {
    try {
      const balance = await new web3.eth.getBalance(account);
      resolve(Number(toEther(balance)).toFixed(4));
    } catch (err) {
      reject(err);
    }
  });
}

function abiSelector(abi) {
  if (!abi || !Object.keys(abi)) {
    return CreateTournamentFactory.abi;
  }
  return abi;
}

function subscribe(param, callback) {
  return web3.eth.subscribe(param, callback);
}

function getChainID() {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await web3.eth.net.getId());
    } catch (err) {
      reject(err);
    }
  });
}

function getMethod(abi = {}, address = "", fn = "", ...args) {
  const contract = new web3.eth.Contract(abiSelector(abi), address);
  return contract.methods[fn](...args);
}

function estimateTxnGas(txn) {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await txn.estimateGas({ from: process.env.REACT_APP_OWNER_ADDRESS })
      );
    } catch (err) {
      reject(err);
    }
  });
}

function getGasPrice() {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await web3.eth.getGasPrice());
    } catch (err) {
      reject(err);
    }
  });
}

function encodeABI(txn) {
  return txn.encodeABI();
}

function getNonce() {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        (await web3.eth.getTransactionCount(
          process.env.REACT_APP_OWNER_ADDRESS
        )) + 1
      );
    } catch (err) {
      reject(err);
    }
  });
}

function signTransaction({ address, data, gas, chainId, privateKey }) {
  return new Promise(async (resolve, reject) => {
    try {
      const gasStationValue = await getFromGasStation();

      const maxPriorityFeePerGas = web3.utils.toWei(
        (Math.floor(gasStationValue.maxPriorityFee) + 1).toString(),
        "Gwei"
      );
      const maxFeePerGas = web3.utils.toWei(
        (Math.floor(gasStationValue.maxFee) + 1).toString(),
        "Gwei"
      );

      resolve(
        await web3.eth.accounts.signTransaction(
          {
            to: address,
            data,
            gas,
            chainId,
            maxPriorityFeePerGas,
            // maxFeePerGas,
          },
          privateKey
        )
      );
    } catch (err) {
      reject(err);
    }
  });
}

function sendSignedTransaction(signedTxn) {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await web3.eth.sendSignedTransaction(signedTxn.rawTransaction));
    } catch (err) {
      reject(err);
    }
  });
}

function changeChain() {
  return new Promise(async (resolve, reject) => {
    const chainId = process.env.REACT_APP_TESTNET_CHAIN_ID;

    if (window.ethereum.networkVersion !== chainId) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(chainId) }],
        });
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (err.code === 4902 || err.data?.originalError?.code === 4902) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "Mumbai Infura",
                chainId: web3.utils.toHex(chainId),
                nativeCurrency: {
                  name: "MATIC",
                  decimals: 18,
                  symbol: "MATIC",
                },
                rpcUrls: [process.env.REACT_APP_ALCHEMY_URL],
              },
            ],
          });
        }
      }
    }
    resolve();
  });
}

async function getKeccak256Hash(string) {
  const messageHash = web3.utils.keccak256(string.toString());
  return messageHash;
}

function sign(message) {
  return new Promise(async (resolve, reject) => {
    try {
      let signature = await web3.eth.personal.sign(message, account);
      resolve(signature);
    } catch (err) {
      reject(err);
    }
  });
}

export default {
  call,
  callVar,
  callVarSpecial,
  send,
  toWei,
  toEther,
  sendSpecial,
  callSpecial,
  account,
  getBalance,
  changeChain,
  getKeccak256Hash,
  sign,
  subscribe,
  getChainID,
  getMethod,
  estimateTxnGas,
  getGasPrice,
  encodeABI,
  getNonce,
  signTransaction,
  sendSignedTransaction,
};
