import React, { useState } from "react";
import { Avatar, AvatarGroup, Typography } from "@mui/material";
import { Box } from "@material-ui/core";
import TradeableCoins from "../Modals/Event/TradeableCoins";

export default function Avatars({ images, sx, name, coins }) {
  //const [tradeableCoinsModal, setTradeableCoinsModal] = useState(false)

  return (
    <Box>
      {/* <TradeableCoins
        coins={coins}
        open={tradeableCoinsModal}
        onClose={() => setTradeableCoinsModal(false)}
      /> */}
      <AvatarGroup
        max={5}
        total={images?.length}
        componentsProps={{
          additionalAvatar: {
            sx: {
              height: 30,
              width: 30,
              background: '#fff',
              fontSize: 14,
              fontWeight: '500'
            }
          }
        }}
        sx={{
          ...sx
        }}
      >
        {images?.map((image, index) => (
          <Avatar
            key={index}
            src={image}
            sx={{
              backgroundColor: "#fff",
              width: sx?.width || 25,
              height: sx?.height || 25,
            }}
          />
        ))}
      </AvatarGroup>
    </Box>
  );
}
