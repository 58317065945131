import { useEffect, useState } from "react"

const useStorage = (key) => {
    const [value, setValue] = useState(sessionStorage.getItem(key))

    const handleChange = () => {
        setValue(sessionStorage.getItem(key))
    }

    const setItem = (key, value) => {
        sessionStorage.setItem(key, value)
        window.dispatchEvent(new Event('storage'))
    }

    const removeItem = (key) => {
        sessionStorage.removeItem(key)
        window.dispatchEvent(new Event('storage'))
    }

    useEffect(() => {
        window.addEventListener('storage', handleChange)
        return () => window.removeEventListener('storage', handleChange)
    }, [])

    return [value, setItem, removeItem]
}

export { useStorage }