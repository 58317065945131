import {
  AppBar,
  Container,
  Toolbar,
  CardActionArea,
  Stack,
  Typography,
  Slide,
  useScrollTrigger,
} from "@mui/material";
import React, { useState } from "react";

export default function ResponsiveBottomAppBar({
  onMenuItemClicked,
  opts,
  menuIndex,
}) {
  const menu = opts;

  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });

    return (
      <Slide appear={false} direction="up" in={!trigger}>
        {children}
      </Slide>
    );
  }

  return (
    <HideOnScroll>
      <AppBar
        postion="fixed"
        sx={{
          top: "auto",
          bottom: 0,
          display: {
            xs: "block",
            md: "none",
          },
          boxShadow: "none",
          borderTop: "1px solid #442d76",
          background: (theme) => theme.palette.primary.main,
        }}
      >
        <Toolbar>
          <Container maxWidth="xl">
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent="space-evenly"
            >
              {menu.map(({ name, route }, index) => (
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    color: index === menuIndex && "#ffc50c",
                    fontSize: 16,
                    pb: 1,
                  }}
                  className="hover-underline-animation"
                  onClick={() => onMenuItemClicked(route, index)}
                >
                  {name.toUpperCase()}
                </Typography>
              ))}
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}
