import React from 'react';
import { Modal, Box, Fade } from '@mui/material'
import './style.css'

export default function Base({ open, onClose, children, sx }) {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Fade
                in={open}
                timeout={300}
            >
                <Box sx={sx ? sx : {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: (theme) => theme.palette.background.modal,
                    borderRadius: 3,
                    p: 3,
                    border: '1px solid #80808050',
                    outline: 'none',
                }}>
                    {children}
                </Box>
            </Fade>
        </Modal>
    )
}
