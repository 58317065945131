import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'
import ResponsiveEventSkeleton from './ResponsiveEventSkeleton'

export default function ResponsiveLoading() {
    return (
        <Box
            sx={{
                mt: 7
            }}
        >
            <Stack
                direction='row'
                flexWrap='wrap'
                //width='100%'
                sx={{
                    display: {
                        xs: 'flex',
                        md: 'none'
                    }
                }}
            >
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
            </Stack>
            <Stack
                direction='row'
                flexWrap='wrap'
                //width='100%'
                sx={{
                    display: {
                        xs: 'none',
                        md: 'flex'
                    }
                }}
            >
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
                <ResponsiveEventSkeleton />
            </Stack>
        </Box>
    )
}
