import { Avatar, CardActionArea, Paper, Stack, Typography } from '@mui/material'
import React from 'react'

export default function TradeableCoinsCards({ name, symbol, image, id }) {
    return (
        <CardActionArea
            href={`https://coingecko.com/en/coins/${id}`}
            target='_blank'
            rel='noreferrer noopener'
        >
            <Paper
                sx={{
                    background: (theme) => theme.palette.primary.darker,
                    px: 2,
                    py: 1
                }}
                elevation={0}
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Stack
                        alignItems='start'
                    >
                        <Typography
                            variant='button'
                            sx={{
                                fontSize: 18,
                                fontWeight: '500'
                            }}
                        >
                            {name}
                        </Typography>
                        <Paper
                            sx={{
                                px: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: (theme) => theme.palette.primary.dark
                            }}
                        >
                            <Typography
                                variant='button'
                                align='center'
                                sx={{
                                    color: (theme) => theme.palette.primary.suppressedText,
                                    fontSize: 12
                                }}
                            >
                                {symbol?.toUpperCase()}
                            </Typography>
                        </Paper>
                    </Stack>
                    <Avatar
                        src={image}
                        sx={{
                            width: 35,
                            height: 35,
                            /* padding: 0.2,
                            background: (theme) => theme.palette.primary.light + "90"  */
                        }}
                    />
                </Stack>
            </Paper>
        </CardActionArea>
    )
}
