import React, { useState } from "react";
import { Stack, Typography, CardActionArea, Box, Toolbar } from "@mui/material";
import ResponsiveBottomAppBar from "./ResponsiveBottomAppBar";

export default function ResponsiveMenu({
  opts,
  onMenuItemClicked,
  menuIndex,
  // bottomMenuIndex,
}) {
  const menu = opts;

  //const [current, setCurrent] = useState(0);

  /* const handleTabChange = (index) => {
		//setCurrent(index);
		onTabChanged(index)
	}; */

  return (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <Stack direction="row" spacing={3} mt={1} mr={5}>
          {menu.map(({ name, route }, index) => {
            // const classes=(index===menuIndex)?"hover-underline-animation yellow":""
            return (
              <Typography
                variant="subtitle1"
                fontWeight={500}
                className="hover-underline-animation"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                  color: index === menuIndex && "#ffc50c",
                  fontSize: 16,
                  pb: 1,
                }}
                onClick={() => onMenuItemClicked(route, index)}
              >
                {name.toUpperCase()}
              </Typography>
            );
          })}
        </Stack>
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            md: "none",
          },
        }}
      >
        <ResponsiveBottomAppBar
          opts={opts}
          onMenuItemClicked={onMenuItemClicked}
          menuIndex={menuIndex}
        />
      </Box>
    </>
  );
}
