import React from 'react'
import { motion } from 'framer-motion'

export default function FlyingUp({ children }) {
    return (
        <motion.div
            initial={{
                opacity: 0,
                y: 50
            }}
            animate={{
                opacity: 1,
                y: 0
            }}
            transition={{
                duration: 0.3
            }}
            exit={{
                opacity: 0,
                y: 50
            }}
            style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap'
            }}
        >
            {children}
        </motion.div>
    )
}
