import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react';
import Reducers from '../../API/Reducers';
import NoOrders from './NoOrders';
import ResponsiveOrdersTable from './ResponsiveOrdersTable';
import './style.css'

export default function Orders({ vestibule_id }) {
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const getData = async () => {
        const response = await Reducers.getTransactions({
            vestibule_id
        })
        if (response.length > 0)
            setData(response)
        setLoading(false)
    };

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <Box
            sx={{
                mt: 1,
                mb: 7,
                px: {
                    xs: 1,
                    md: 2
                }
            }}
        >
            <Stack
                spacing={1}
            >
                {
                    loading ?
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '80vh',
                            }}
                        >
                            <CircularProgress
                                thickness={8}
                                size={30}
                                color='secondary'
                            />
                        </Box>
                        :
                        data ?
                            <ResponsiveOrdersTable
                                orders={data}
                            />
                            :
                            <NoOrders />
                }
            </Stack>
        </Box>
    )
}
