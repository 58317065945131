import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Base from './Base'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Lottie from 'react-lottie'
import successAnimation from '../../animations/completed-lottie.json'
import errorAnimation from '../../animations/error-lottie.json'

export default function BasicLoader({ open, onClose, completed, onFinished, error }) {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: error ? errorAnimation : successAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <Base
            open={open}
            onClose={onClose}
        >
            <Box>
                <Stack
                    spacing={2}
                    alignItems='center'
                >
                    {!completed ?
                        <Box
                            sx={{
                                height: 200,
                                width: 300,
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress
                                size={80}
                                color='secondary'
                            />
                        </Box>
                        :
                        <Lottie options={defaultOptions} width={300} height={200} />
                    }
                    <Stack
                        alignItems='center'
                    >
                        <Typography
                            sx={{
                                fontSize: 30
                            }}
                            textAlign='center'
                        >
                            {!completed ? 'Waiting for confirmation' : !error ? 'Transaction confirmed' : error.code === 4001 ? 'Transaction rejected' : 'Transaction failed'}
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{
                                color: theme => theme.palette.primary.suppressedText,
                                textAlign: {
                                    xs: 'left',
                                    md: 'center'
                                }
                            }}
                        >
                            {!completed ? 'Please wait while we complete all the steps.' : !error ? 'Transaction was successfully executed.' : 'Transaction failed unexpectedly.'}
                            <br />
                            {!completed ? 'Please DO NOT reload the page.' : 'Click FINISH to finish the process.'}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    width='100%'
                    sx={{
                        mt: 4
                    }}
                >
                    <Button
                        variant='contained'
                        disableElevation
                        fullWidth
                        sx={{
                            ":disabled": {
                                border: "1px solid #80808080",
                                background: 'none'
                            },
                            background: theme => theme.palette.primary.light,
                            "&:hover": {
                                background: theme => theme.palette.primary.light + "aa"
                            }
                        }}
                        disabled={!completed}
                        onClick={onFinished || onClose}
                    >
                        <Stack
                            direction='row'
                            alignItems='center'
                        >
                            FINISH
                            <ChevronRightIcon />
                        </Stack>
                    </Button>
                </Stack>
            </Box>
        </Base>
    )
}
