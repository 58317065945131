import React, { useEffect, useState } from "react";
import { Stack, TextField, InputAdornment } from "@mui/material";
import "./style.css";
import StyledTextField from "../CreateEventNew/StyledTextField";

export default function ResponsiveRangeInput({
  placeholders,
  updateCritera,
  prefix = false,
}) {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState();

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        placeholder={placeholders[0]}
        id="outlined-basic"
        type="number"
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: (theme) => theme.palette.secondary.main,
            },
          },
        }}
        InputProps={{
          inputProps: {
            min: 0,
          },
          startAdornment: prefix && (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
        className="text-field"
        onChange={(event) => {
          console.log(event.target.value, " min");
          setMinValue(Number(event.target.value));
          updateCritera(Number(event.target.value), maxValue);
          // if (
          //   event.target.value &&
          //   event.target.value !== "" &&
          //   !isNaN(event.target.value)
          // ) {
          //   if (!maxValue || Number(event.target.value) <= maxValue) {
          //     setMinValue(Number(event.target.value));
          //     updateCritera(Number(event.target.value), maxValue);
          //   } else {
          //     //setMinValue(0);
          //     updateCritera(0);
          //   }
          // } else {
          //   //setMinValue(0);
          //   updateCritera(0);
          // }
        }}
      />
      <TextField
        placeholder={placeholders[1]}
        type="number"
        className="text-field"
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: (theme) => theme.palette.secondary.main,
            },
          },
        }}
        onChange={(event) => {
          console.log(event.target.value);
          setMaxValue(Number(event.target.value));
          updateCritera(minValue, Number(event.target.value));
          // if (
          //   event.target.value &&
          //   event.target.value !== "" &&
          //   !isNaN(event.target.value)
          // ) {
          //   console.log(event.target.value, " Min Value ", Number(minValue));
          //   if (Number(minValue) <= Number(event.target.value)) {
          //     setMaxValue(Number(event.target.value));
          //     updateCritera(minValue, Number(event.target.value));
          //   } else {
          //     setMaxValue(undefined);
          //     setMinValue(0);
          //     updateCritera(0, undefined);
          //   }
          // } else {
          //   setMaxValue(undefined);
          //   setMinValue(0);
          //   updateCritera(0, undefined);
          // }
        }}
        id="outlined-basic"
        variant="outlined"
        InputProps={{
          inputProps: {
            min: 0,
          },
          startAdornment: prefix && (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
