import { Cancel } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import SupportedWalletsCards from '../../Cards/SupportedWalletsCards'
import Base from '../Base'

export default function SupportedWallets({
  open,
  onClose,
  onConnectionRequest
}) {

  const supportedWallets = ['metamask']

  return (
    <Base
      open={open}
      onClose={onClose}
    >
      <Stack
        spacing={2}
        sx={{
          minHeight: 500,
          width: 270,
        }}
        justifyContent='space-between'
      >
        <Stack
          spacing={2}
        >
          <Stack
            justifyContent='space-between'
            direction='row'
          >
            <Typography
              variant='button'
              sx={{
                fontSize: 22
              }}
            >
              Select a wallet
            </Typography>
            <IconButton
              onClick={onClose}
            >
              <Cancel />
            </IconButton>
          </Stack>
          {window.ethereum ?
            <Stack
              spacing={2}
            >
              {
                supportedWallets.map(wallet => (
                  <SupportedWalletsCards
                    wallet={wallet}
                    onConnectionRequest={onConnectionRequest}
                  />
                ))
              }
            </Stack>
            :
            <Typography
              variant='button'
              sx={{
                fontSize: 18
              }}
            >
              No supported wallets found
            </Typography>
          }
        </Stack>
        <Stack
          spacing={0.5}
        >
          <Typography
            variant='button'
            sx={{
              fontSize: 16
            }}
          >
            DISCLAIMER
          </Typography>
          <Typography
            variant='body2'
            color='GrayText'
          >
            We do not collect personal information on this site and only use the wallet with your permission. DO NOT use the wallet for transactions you do not understand properly.
          </Typography>
        </Stack>
      </Stack>
    </Base>
  )
}
