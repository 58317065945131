import { CircularProgress } from '@mui/material'
import React from 'react'

export default function Circular() {
  return (
    <CircularProgress 
        thickness={10}
        size={30}
        color='secondary'
    />
  )
}
