import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "../EventDetails/constants";

function Header({ data, headerText }) {
	const [loading, setLoading] = useState(true);
	const [crypto, setCrypto] = useState();
	const { width, height } = useWindowDimensions();
	useEffect(() => {
		setCrypto(data);
		setLoading(false);
	}, [data]);

	return (
		<>
			{loading ? (
				<Typography variant="button" sx={{ fontSize: 24 }}>
					Loading
				</Typography>
			) : (
				<Box
					sx={{
						// border: "1px solid #553398",
						width: "100%",
						px: 2,
						py: 1,
						// borderRadius: 1,
						display: "flex",
						alignItems: "center",
						// m: 0.5,
						position: "absolute",
						top: "5px",
						// left: "12px",
						zIndex: 100,
					}}
				>
					<img
						src={data && data.crypto_image ? data.crypto_image : "unknown"}
						style={{
							width: 28,
							height: 28,
						}}
					/>
					<Stack direction={"row"} sx={{ ml: 2 }}>
						<Typography variant="button" sx={{ textTransform: "none" }}>
							{data && data.crypto_name ? data.crypto_name : "unknown"}
						</Typography>
						<Typography variant="button" sx={{ opacity: 0.5, mx: 0.5 }}>
							({data && data.crypto_symbol ? data.crypto_symbol : "unknown"})
						</Typography>
					</Stack>
					<Typography>/</Typography>
					<Stack direction={"row"} sx={{ ml: 0.5 }}>
						<Typography variant="button" sx={{ textTransform: "none" }}>
							U.S. Dollar
						</Typography>
						<Typography variant="button" sx={{ opacity: 0.5, ml: 0.5 }}>
							(USD)
						</Typography>
					</Stack>
					<Box
						sx={{
							display: !headerText ? 'none' : "flex",
							alignItems: "center",
							position: width >= 1200 ? "relative" : "absolute",
							top: width >= 1200 ? 0 : 35,
							left: width >= 1200 ? 0 : 30,
						}}
					>
						<Stack direction={"row"} sx={{ ml: 1 }}>
							<Typography variant="button" sx={{ textTransform: "none" }}>
								O
							</Typography>
							<Typography
								variant="button"
								sx={{
									//opacity: 0.5,
									ml: 0.5,
									color: Number(headerText?.open) > 0 ? '#4bffb5' : '#ff4976'
								}}
							>
								{headerText?.open}
							</Typography>
						</Stack>
						<Stack direction={"row"} sx={{ ml: 1 }}>
							<Typography variant="button" sx={{ textTransform: "none" }}>
								H
							</Typography>
							<Typography
								variant="button"
								sx={{
									//opacity: 0.5,
									ml: 0.5,
									color: Number(headerText?.high) > 0 ? '#4bffb5' : '#ff4976'
								}}>
								{headerText?.high}
							</Typography>
						</Stack>
						<Stack direction={"row"} sx={{ ml: 1 }}>
							<Typography variant="button" sx={{ textTransform: "none" }}>
								L
							</Typography>
							<Typography
								variant="button"
								sx={{
									//opacity: 0.5,
									ml: 0.5,
									color: Number(headerText?.low) > 0 ? '#4bffb5' : '#ff4976'
								}}>
								{headerText?.low}
							</Typography>
						</Stack>
						<Stack direction={"row"} sx={{ ml: 1 }}>
							<Typography variant="button" sx={{ textTransform: "none" }}>
								C
							</Typography>
							<Typography
								variant="button"
								sx={{
									//opacity: 0.5,
									ml: 0.5,
									color: Number(headerText?.close) > 0 ? '#4bffb5' : '#ff4976'
								}}>
								{headerText?.close}
							</Typography>
						</Stack>
					</Box>
				</Box>
			)}
		</>
	);
}

export default Header;
