import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

export default function NoTrading() {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(80vh - 10rem)'
            }}
        >
            {/* <Typography
                variant="h5"
            >
                This event has no trade-able coins
            </Typography> */}
            <CircularProgress
                size={40}
                thickness={8}
                color='secondary'
            />
        </Box>
    )
}
