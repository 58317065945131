import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Reducers from "../../API/Reducers";
import Balance from "./Balance";
import Chart from "./Chart";
import NoPortfolio from "./NoPortfolio";
import PortfolioList from "./PortfolioList";
import PortfolioMetadata from "./PortfolioMetadata";

export default function Portfolio({ vestibule_id }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    async function getData() {
      var response = await Reducers.getPortfolio({
        vestibule_id,
      });
      if (Object.keys(response.coins.coin).length > 0) {
        let coins = [
          ...Object.keys(response.coins.coin).map((key) => {
            return { crypto_id: key, ...response.coins.coin[key] };
          }),
        ];
        response.coins.coin = coins;
        response.coins.asset_value_array = [
          ...response.coins.asset_value_array.map((i) => ({
            time: i.date,
            value: i.asset_value,
          })),
        ];
      }
      setLoading(false);
      setData(response);
    }

    getData();
  }, []);

  return (
    <Stack
      sx={{
        mt: 1,
        mb: 5,
      }}
      spacing={1}
    >
      {!loading ? (
        data?.coins?.coin &&
        Object.keys(data?.coins?.coin).length > 0 &&
        data?.coins?.asset_value_array?.length > 0 ? (
          <Stack spacing={4}>
            <Chart data={data?.coins?.asset_value_array} />
            <Box
              sx={{
                px: {
                  xs: 1,
                  md: 2,
                },
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  divider={
                    <Divider
                      orientation="vertical"
                      sx={{
                        background: (theme) =>
                          theme.palette.primary.darker + "90",
                      }}
                      flexItem
                    />
                  }
                >
                  <Stack spacing={1} width="100%">
                    <Typography variant="h5" fontWeight="500">
                      Holdings
                    </Typography>
                    <PortfolioList coins={data?.coins?.coin} />
                  </Stack>
                  <PortfolioMetadata coins={data?.coins?.coin} />
                </Stack>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    md: "none",
                  },
                }}
              >
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Typography variant="h5" fontWeight="500">
                      Holdings
                    </Typography>
                    <PortfolioList coins={data?.coins?.coin} />
                  </Stack>
                  <PortfolioMetadata coins={data?.coins?.coin} />
                </Stack>
              </Box>
            </Box>
          </Stack>
        ) : (
          <NoPortfolio />
        )
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <CircularProgress thickness={8} size={30} color="secondary" />
        </Box>
      )}
    </Stack>
  );
}
