import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LinearLoading from "../CreateEvent/LinearLoading";
import CryptosInfo from "./CryptoInfo";
import Cryptos from "./Cryptos";
import WebUI from "./WebUI";
import MobileUI from "./MobileUI";
import io from "socket.io-client";
import MobileCryptoInfo from "./MobileCryptoInfo";
import NoTrading from "./NoTrading";
import Transaction from "./Transaction/";
import TransactionCompleted from "../Modals/Transaction/TransactionCompleted";
import DateUtils from "../../utils/date.utils";
import Reducers from "../../API/Reducers";

function Trading({ event_id, vestibule_id }) {
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({
    crypto_id: "bitcoin",
    crypto_name: "Bitcoin",
    crypto_symbol: "BTC",
    crypto_image:
      "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579",
  });
  const [buySell, setBuySell] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [cryptoInfoDrawer, setCryptoInfoDrawer] = useState(false);
  const [cryptoInfo, setCryptoInfo] = useState();
  const [drawerType, setDrawerType] = useState("buy");
  const [transactionCompleted, setTransactionCompleted] = useState(false);
  const [isEventFinished, setEventFinished] = useState(false);

  const toggleDrawer = (e) => {
    // check if the drawer has certain MUI classes or not
    // if present toggleDrawer
    // if the event is null due to variety usage of this method
    // toggle the drawer by default
    if (e && e.target) {
      //console.log(e?.target?.className)
      if (
        typeof e?.target?.className === "string" &&
        ((e?.target?.className?.includes("MuiBox-root") &&
          !e?.target?.className?.includes("transaction") &&
          !e?.target?.className?.includes("currency-list-btn")) ||
          e?.target?.className?.includes("transaction-backdrop")) &&
        !e?.target?.className?.includes("transaction-base")
      )
        setBuySell(!buySell);
    } else {
      setBuySell(!buySell);
    }
  };

  const toggleSideDrawer = () => {
    setDrawer(!drawer);
  };

  const toggleCryptoDrawer = () => {
    setCryptoInfoDrawer(!cryptoInfoDrawer);
  };

  const onCoinSwitched = (coin) => {
    setSelected(coin);
  };

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_BASE_URL, {
      query: {
        event_id,
      },
    });

    newSocket.on("prices-update", (response) => {
      //if (!buySell) {
      if (response.data.length === 0) setLoading(false);
      setCoins(response.data);
      //}
    });

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    async function getEventDetails() {
      const { end_date } = await Reducers.getEvent(event_id);

      if (
        Number(DateUtils.toUnix(new Date().toUTCString())) > Number(end_date)
      ) {
        setEventFinished(true);
      }
    }
    console.log("time fetched");
    getEventDetails();
  }, [drawerType]);

  const handleTransactionCompleted = () => {
    toggleDrawer();
    setTransactionCompleted(true);
    setTimeout(() => {
      setTransactionCompleted(false);
    }, 2000);
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: {
            xs: 55,
            md: 65,
          },
          left: 0,
          right: 0,
        }}
      >
        <LinearLoading display={loading} />
      </Box>
      <TransactionCompleted
        open={transactionCompleted}
        onClose={() => setTransactionCompleted(false)}
      />
      {coins.length > 0 ? (
        <>
          <WebUI
            loading={loading}
            setLoading={setLoading}
            setCryptoInfo={setCryptoInfo}
            coins={coins}
            selected={selected}
            setSelected={setSelected}
            toggleDrawer={toggleDrawer}
            setDrawerType={setDrawerType}
          />
          <MobileUI
            selected={selected}
            toggleDrawer={toggleDrawer}
            toggleSideDrawer={toggleSideDrawer}
            setDrawerType={setDrawerType}
            setLoading={setLoading}
            loading={loading}
          />
          <Drawer
            anchor={"bottom"}
            elevation={10}
            open={drawer}
            variant="temporary"
            onClose={() => {
              toggleSideDrawer();
              setLoading(false);
            }}
            /* sx={{
								"-webkit-backdrop-filter": "blur(4px)",
								backdropFilter: "blur(4px)",
							}} */
            PaperProps={{
              sx: {
                background: "transparent",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                backgroundColor: "#0009",
              }}
            >
              <Cryptos
                data={coins}
                selected={selected}
                setSelected={setSelected}
                loading={loading}
                toggleSideDrawer={toggleSideDrawer}
                height={"60vh"}
              />
              <CryptosInfo
                data={selected}
                cryptoData={
                  coins.filter((i) => i.crypto_id === selected.crypto_id)[0]
                }
                toggleDrawer={toggleDrawer}
                setLoading={setLoading}
                setCryptoInfo={setCryptoInfo}
                height={250}
                toggleCryptoDrawer={toggleCryptoDrawer}
              />
            </Box>
          </Drawer>
          <Drawer
            anchor={"bottom"}
            elevation={10}
            open={buySell}
            variant="temporary"
            onClose={toggleDrawer}
            PaperProps={{
              sx: {
                background: "transparent",
                height: {
                  xs: "75vh",
                  xl: "auto",
                },
                //width: 600
              },
              //   height: {
              //     xs: "35vh",
              //     xl: "50%",
              //   },

              onClick: toggleDrawer,
            }}
            BackdropProps={{
              className: "transaction-backdrop",
            }}
            className="transaction-drawer"
          >
            <Transaction
              onCoinSwitched={onCoinSwitched}
              tab={drawerType === "buy" ? 0 : 1}
              crypto={cryptoInfo}
              open={buySell}
              onClose={toggleDrawer}
              tradable_coins={coins}
              onTransactionCompleted={handleTransactionCompleted}
              vestibule_id={vestibule_id}
              eventId={event_id}
            />
          </Drawer>
          <Drawer
            anchor={"bottom"}
            elevation={10}
            open={cryptoInfoDrawer}
            variant="temporary"
            onClose={toggleCryptoDrawer}
            PaperProps={{
              sx: {
                background: "transparent",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                backgroundColor: "#0009",
              }}
            >
              <MobileCryptoInfo
                data={selected}
                cryptoData={
                  coins.filter((i) => i.crypto_id === selected.crypto_id)[0]
                }
                toggleDrawer={toggleDrawer}
                setLoading={setLoading}
                setCryptoInfo={setCryptoInfo}
                height={"55vh"}
                toggleCryptoDrawer={toggleCryptoDrawer}
              />
            </Box>
          </Drawer>
        </>
      ) : (
        <NoTrading />
      )}
    </>
  );
}

export default Trading;

// import { Box, Typography } from "@mui/material";
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import Card from "./Card";
// import CurrencyTable from "./Table";

// function Trading() {
// 	const [coins, setCoins] = useState([]);
// 	const [loading, setLoading] = useState(true);
// 	useEffect(() => {
// 		const fetchCoins = async () => {
// 			const response = await axios.get(
// 				"https://testapi.bricksprotocol.com/api/v1/coins"
// 			);
// 			console.log(response.data);
// 			setCoins([...response.data]);
// 			setLoading(false);
// 		};
// 		fetchCoins();
// 	}, []);

// 	return (
// 		<Box
// 			sx={{
// 				mt: 10,
// 			}}
// 		>
// 			{loading ? (
// 				<Typography sx={{ fontSize: 20 }}>Loading</Typography>
// 			) : (
// 				<>
// 					<Box
// 						sx={{
// 							display: { xs: "none", lg: "flex" },
// 							flexDirection: "column",
// 							padding: 2,
// 						}}
// 					>
// 						<CurrencyTable data={coins} />
// 					</Box>
// 					<Box
// 						sx={{
// 							display: { xs: "flex", lg: "none" },
// 							flexDirection: "column",
// 							// padding: 2,
// 							pb: 4,
// 						}}
// 					>
// 						{coins.map((item, index) => {
// 							return <Card item={item} index={index} />;
// 						})}
// 					</Box>
// 				</>
// 			)}
// 		</Box>
// 	);
// }

// export default Trading;
