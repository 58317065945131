import React, { useEffect, useRef } from 'react'
import './style.css'
import { Box } from "@mui/material"

export default function OneDirectionalLoader({ onAnimationEnd }) {
    const boxRef = useRef()

    useEffect(() => {
        if (boxRef.current) {
            boxRef.current.addEventListener('animationend', onAnimationEnd)
        }
        return () => boxRef.current && boxRef.current.removeEventListener('animationend', onAnimationEnd)
    }, [boxRef.current])

    return (
        <Box
            sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: '100%',
                borderRadius: 2,
                zIndex: -1
            }}
            className='one-d'
            ref={boxRef}
        >

        </Box>
    )
}
