/**
 * The main data structure for ERC20 token manipulations
 * Please do not change any key value
 * You may change the pair value according to your needs
 * You can change the key value but make sure wherever the key is being used, you update them there too
 *
 * The main "mumbai" object key is related to the REACT_APP_SUPPORTED_TESTNETS env variable
 * If you need to change the network name, change it both here as well as the env variable mentioned above
 */

import { client as web3 } from "./web3";

const getWei = (value, decimals) => {
  value = value.toString();
  let numberDecimals = web3.utils.toBN(countDecimals(value));
  let formattedInput = value.split(".")[0];
  if (numberDecimals > 0) formattedInput = value.split(".").join("");
  decimals = web3.utils.toBN(decimals);
  let amount = web3.utils.toBN(formattedInput);
  value = amount.mul(web3.utils.toBN(10).pow(decimals));
  return value.div(web3.utils.toBN(10).pow(numberDecimals)).toString();
  //web3.utils.toBN(value).mul(web3.utils.toBN(10).pow(web3.utils.toBN(decimals))).toString()
};

const getEther = (value, decimals) =>
  (value / Math.pow(10, decimals)).toString();

const countDecimals = function (value) {
  if (Math.floor(parseFloat(value)) === parseFloat(value)) return 0;
  return value.toString().split(".")[1].length || 0;
};

export default {
  mumbai: {
    "lending-pool-address-provider":
      "0x178113104fEcbcD7fF8669a0150721e231F0FD4B",
    "gateway-aave": "0xee9eE614Ad26963bEc1Bec0D2c92879ae1F209fA",
    assets: {
      matic: {
        address: {
          "aave-token-address": "0x89a6AE840b3F8f489418933A220315eeA36d11fF",
          "token-address": "0xb685400156cF3CBE8725958DeAA61436727A30c3",
          "unwrapped-token-address":
            "0x0000000000000000000000000000000000001010",
        },
        decimals: 18,
        image:
          "https://assets.coingecko.com/coins/images/14073/large/matic.png?1628852392",
        isNativeAsset: true,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
      dai: {
        address: {
          "aave-token-address": "0xDD4f3Ee61466C4158D394d57f3D4C397E91fBc51",
          "token-address": "0x9A753f0F7886C9fbF63cF59D0D4423C5eFaCE95B",
        },
        decimals: 18,
        image:
          "https://assets.coingecko.com/coins/images/9956/large/4943.png?1636636734",
        isNativeAsset: false,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
      usdc: {
        address: {
          "aave-token-address": "0xCdc2854e97798AfDC74BC420BD5060e022D14607",
          "token-address": "0x9aa7fEc87CA69695Dd1f879567CcF49F3ba417E2",
        },
        decimals: 6,
        image:
          "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389",
        isNativeAsset: false,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
      usdt: {
        address: {
          "aave-token-address": "0x6Ca4abE253bd510fCA862b5aBc51211C1E1E8925",
          "token-address": "0x21C561e551638401b937b03fE5a0a0652B99B7DD",
        },
        decimals: 6,
        image:
          "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707",
        isNativeAsset: false,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
      wbtc: {
        address: {
          "aave-token-address": "0xde230bC95a03b695be69C44b9AA6C0e9dAc1B143",
          "token-address": "0x85E44420b6137bbc75a85CAB5c9A3371af976FdE",
        },
        decimals: 8,
        image:
          "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1548822744",
        isNativeAsset: false,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
      weth: {
        address: {
          "aave-token-address": "0x685bF4eab23993E94b4CFb9383599c926B66cF57",
          "token-address": "0xd575d4047f8c667E064a4ad433D04E25187F40BB",
        },
        decimals: 18,
        image:
          "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
        isNativeAsset: false,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
    },
    image:
      "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912",
    isNativeAsset: false,
    alias: {
      weth: "weth",
      dai: "dai",
      "wrapped-bitcoin": "wbtc",
      tether: "usdt",
      "matic-network": "matic",
      "usd-coin": "usdc",
    },
    yieldSources: ["AAVE"],
  },
};
