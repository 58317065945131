import React from 'react'
import { Box, Stack, Typography, useScrollTrigger } from '@mui/material'

export default function ResponsiveLogo() {
    const trigger = useScrollTrigger({
        //disableHysteresis: true,
        threshold: 0,
    });

    return (
        <a href='/' style={{
            textDecoration: 'none',
            color: '#fff'
        }}>
            <Box
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block'
                    },
                }}
            >
                <Stack
                    direction='row'
                    spacing={2}
                    alignItems='center'
                    justifyContent='center'
                >
                    <img
                        src='/logoV2.png'
                        width={40}
                    />
                    <Typography
                        fontWeight={500}
                        fontSize={25}
                        variant='button'
                        align='center'
                        sx={{
                            transition: '0.3s ease all',
                            mb: 0,
                            pb: 0
                        }}
                        className={`${trigger ? 'app-name-hide' : 'app-name-show'}`}
                    >
                        Bricks Protocol
                    </Typography>
                </Stack>
            </Box>
            <Box
                sx={{
                    display: {
                        xs: 'flex',
                        md: 'none'
                    },
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img
                    src='/logoV2.png'
                    width={30}
                />
            </Box>
        </a>
    )
}
