import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import IERC20 from '../dApp/abi/IERC20.json'
import ERC20 from '../dApp/erc20.js'
import Web3Utils from '../utils/web3.utils'

function getBalance(asset = "", account_address = "") {
    if (!asset || !account_address)
        throw "NullValueException: Neither asset nor account_address can be null"
    return new Promise(async (resolve, reject) => {
        try {
            const asset_details = ERC20[process.env.REACT_APP_SUPPORTED_TESTNETS]['assets'][asset.toLowerCase()]
            const asset_address = asset_details['isNativeAsset'] ? asset_details['address']['unwrapped-token-address'] : asset_details['address']['token-address']
            const balance = await Web3Utils.callSpecial(IERC20.abi, asset_address, null, 'balanceOf', account_address)
            resolve(parseFloat(asset_details.toEther(balance.toString())).toFixed(2))
        }
        catch (err) {
            reject(err)
        }
    })
}

export default { getBalance }