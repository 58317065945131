import { Box, Link, Stack } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as Discord } from '../../icons/discord.svg'
import { ReactComponent as Twitter } from '../../icons/twitter.svg'

export default function ResponsiveSocials() {
    const DiscordComponent = () => {
        return (
            <Link
                target='_blank'
                rel='noreferrer noopener'
                href='https://bit.ly/bricks_discord'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Discord
                    height={30}
                    width={30}
                    className='socials'
                />
            </Link>
        )
    }

    const TwitterComponent = () => {
        return (
            <Link
                target='_blank'
                rel='noreferrer noopener'
                href='https://twitter.com/BricksProtocol'
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Twitter
                    height={30}
                    width={30}
                    className='socials'
                />
            </Link>
        )
    }
    return (
        <Box
            sx={{
                display: {
                    xs: 'none',
                    md: 'block'
                },
            }}
        >
            <Stack
                direction='row'
                spacing={1.3}
                ml={3}
                alignItems='center'
            >
                <DiscordComponent />
                <TwitterComponent />
            </Stack>
        </Box>
    )
}
