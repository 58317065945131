import React, { useState } from 'react'
import Event from '../Event'
import FlyingUp from '../../Animations/FlyingUp'
import EmptyPage from '../EmptyPage'
import { Grid, Box } from '@mui/material'
export default function PastEvents({
    events,
    onEventSelected,
    onJoinClicked
}) {
    return (
        <FlyingUp>
            {events.length ?
                <>
                    <Box
                        sx={{
                            /* display: {
                                xs: 'none',
                                md: 'block'
                            } */
                            width: '100%'
                        }}
                    >
                        <Grid container spacing={1}>
                            {
                                events.map(event => (
                                    <Grid
                                        item
                                        xl={12 / 2}
                                        lg={12 / 2}
                                        xs={12}
                                    >
                                        <Event
                                            event={event}
                                            onClick={() => onEventSelected(event)}
                                            onJoinClicked={() => onJoinClicked({ ...event })}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                    {/* <Box
                        sx={{
                            display: {
                                xs: 'block',
                                md: 'none'
                            },
                            width: '100%'
                        }}
                    >
                        {
                            events.map(event => (
                                <Event
                                    event={event}
                                    onClick={() => onEventSelected(event)}
                                    onJoinClicked={() => onJoinClicked({ ...event })}
                                />
                            ))
                        }
                    </Box> */}
                </>
                :
                <EmptyPage
                    type='past'
                />
            }
        </FlyingUp>
    )
}
