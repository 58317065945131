import {
	Avatar,
	AvatarGroup,
	Box,
	Button,
	Divider,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ERC20 from "../../dApp/erc20.js";
import DateUtils from "../../utils/date.utils";
import "./index.css";
import Web3Utils from "../../utils/web3.utils";
import Avatars from "../Events/Avatars";
import TradeableCoins from "../Modals/Event/TradeableCoins.js";

function Details({ event, toggleDrawer }) {
	const images = [/* 
		"https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579",
		"https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
		"https://assets.coingecko.com/coins/images/5/large/dogecoin.png?1547792256",
		"https://assets.coingecko.com/coins/images/11939/large/shiba.png?1622619446",
		"https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912", */
		...event.tradeable_coins?.slice(0, 5)?.map(i => i["coin.crypto_image"])
	];

	const [modal, setModal] = useState({
		open: false,
		coins: []
	})

	const asset_details = ERC20[process.env.REACT_APP_SUPPORTED_TESTNETS]['assets'][event.asset]

	const handleViewTradeableCoins = (e, coins) => {
		if (e) {
			if (e?.target?.classList?.contains('more_coins')) {
				e.stopPropagation()
				setModal({
					open: !modal.open,
					coins: modal.coins.length > 0 ? [] : coins
				})
			}
		}
		else {
			setModal({
				open: !modal.open,
				coins: modal.coins.length > 0 ? [] : coins
			})
		}
	}

	const closeTradeableCoins = () => {
		setModal({
			open: false,
			coins: []
		})
	}

	return (
		<Box sx={{ mt: 2, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
			<TradeableCoins
				open={modal.open}
				onClose={closeTradeableCoins}
				coins={event.tradeable_coins}
			/>
			<Paper
				sx={{
					p: 0.2,
					px: 1,
					// width: 65,
					background: (theme) =>
						event.status === "completed"
							? theme.palette.misc.error
							: event.status === "ongoing"
								? theme.palette.misc.success
								: theme.palette.misc.warning,
					position: "absolute",
					right: 10,
					top: 10,
				}}
			>
				<Typography
					variant="button"
					color="#fff"
					sx={{
						fontSize: {
							xs: 10,
							md: 12,
						},
					}}
				>
					{event.status}
				</Typography>
			</Paper>
			{/* <Details /> */}
			{event && event.asset && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
					}}
				>
					<Avatar
						src={ERC20["mumbai"]["assets"][event.asset]["image"]}
						sx={{
							width: 100,
							height: 100,
							backgroundColor: (theme) => theme.palette.primary.dark /*+ "90"*/,
							p: 0.5,
						}}
					/>
				</Box>
			)}
			<Typography
				sx={{ textAlign: "center", fontSize: 28, fontWeight: "bold" }}
			>
				{event.title}
			</Typography>
			<Stack
				direction='row'
				spacing={1}
			>
				<Typography sx={{ textAlign: "center" }}>
					{event &&
						event.start_date &&
						event.end_date &&
						DateUtils.format(DateUtils.unixToDate(event.start_date))}
				</Typography>
				<Typography
					sx={{ textAlign: "center", color: theme => theme.palette.primary.suppressedText }}
				>
					{DateUtils.formatTime(DateUtils.unixToDate(event.start_date))}
				</Typography>
				<Typography>-</Typography>
				<Typography sx={{ textAlign: "center" }}>
					{DateUtils.format(DateUtils.unixToDate(event.end_date))}
				</Typography>
				<Typography
					sx={{ textAlign: "center", color: theme => theme.palette.primary.suppressedText }}
				>
					{DateUtils.formatTime(DateUtils.unixToDate(event.end_date))}
				</Typography>
			</Stack>
			{/* <Typography sx={{ textAlign: "center", color: theme => theme.palette.primary.suppressedText }}>
				{event &&
					event.start_date &&
					event.end_date &&
					DateUtils.formatTime(DateUtils.unixToDate(event.start_date))}{" "}
				- {DateUtils.formatTime(DateUtils.unixToDate(event.end_date))}
			</Typography> */}
			<Box sx={{ width: '100%', px: 2 }}>
				<Typography
					sx={{
						textAlign: "center",
						position: "absolute",
						top: 40,
						right: 10,
						color: "#afafaf",
					}}
				>
					Created on
					<br />
					<span
						style={{
							fontWeight: "bold",
							color: "white",
						}}
					>
						{event && event.createdAt ? DateUtils.format(event.createdAt) : "unknown"}
					</span>
				</Typography>
				{/* <Typography
				sx={{
					textAlign: "center",
					color: (theme) => theme.palette.secondary.main,
				}}
				className="description"
			>
				{event.description}
			</Typography> */}
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						mt: 4
					}}
				>
					<Typography
						variant="button"
						color="#fff"
						sx={{
							fontSize: 16,
						}}
					>
						Joining fees
					</Typography>
					<Typography
						variant="button"
						color="#fff"
						sx={{
							fontSize: 16,
						}}
					>
						{event.joining_fees_in_wei &&
							Number(asset_details.toEther(event.joining_fees_in_wei)) > 0
							? `${asset_details.toEther(event.joining_fees_in_wei)} ${event.asset.toUpperCase()}`
							: "FREE"}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						mt: 0.5,
					}}
				>
					<Typography
						variant="button"
						color="#fff"
						sx={{
							fontSize: 16,
						}}
					>
						Initial Contribution
					</Typography>
					<Typography
						variant="button"
						color="#fff"
						sx={{
							fontSize: 16,
						}}
					>
						{event.initial_pool_in_wei &&
							asset_details.toEther(event.initial_pool_in_wei)}{" "}
						{event.asset.toUpperCase()}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						mt: 0.5,
					}}
				>
					<Typography
						variant="button"
						color="#fff"
						sx={{
							fontSize: 16,
						}}
					>
						Pooled Amount
					</Typography>
					<Typography
						variant="button"
						color="#fff"
						sx={{
							fontSize: 16,
						}}
					>
						{event.current_pool_amount && event.current_pool_amount != 0
							? Number(asset_details.toEther(event.current_pool_amount)).toFixed(4)
							: "0"}{" "}
						{event.asset.toUpperCase()}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						mt: 0.5,
					}}
				>
					<Typography
						variant="button"
						color="#fff"
						sx={{
							fontSize: 16,
						}}
					>
						Participants
					</Typography>
					<Typography
						variant="button"
						color="#fff"
						sx={{
							fontSize: 16,
						}}
					>
						{event.participants}&nbsp;{event.participants === 1 ? 'PARTICIPANT' : 'PARTICIPANTS'}
					</Typography>
				</Box>
				{event.tradeable_coins?.length > 0 &&
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							mt: 2,
							flexDirection: "column",
						}}
					>
						<Typography
							variant="button"
							color={(theme) => theme.palette.secondary.main}
							sx={{
								fontSize: 16,
							}}
						>
							Tradable Coins
						</Typography>
						<Stack
							direction="row"
							spacing={1}
							sx={{
								/* display: {
									xs: "none",
									md: "flex",
								}, */
								alignItems: "center",
								// justifyContent: "center",
								mt: 0.5,
							}}
						>
							{/* <AvatarGroup
						sx={{
							display: {
								xs: "none",
								md: "flex",
							},
						}}
					>
						{images.map((image, index) => (
							<Avatar
								key={index}
								src={image}
								sx={{
									backgroundColor: "#fff",
									width: 37,
									height: 37,
								}}
							/>
						))}
					</AvatarGroup> */}
							<Avatars
								images={images}
							/>
							<Typography
								variant="body1"
								color="#fff"
							/* sx={{
								display: {
									xs: "none",
									md: "block",
								},
							}} */
							>
								{
									event.tradeable_coins?.length - 5 > 0 ?
										<span className='more_coins' onClick={(e) => handleViewTradeableCoins(e, event.tradeable_coins)}>
											{`+${event.tradeable_coins?.length - 5} MORE`}
										</span>
										:
										null
								}
							</Typography>
						</Stack>
					</Box>
				}
				<Divider
					sx={{
						marginTop: 2
					}}
				/>
				<Stack
					spacing={1}
					marginTop={2}
					mb={2}
				>
					<Typography
						variant="h6"
					>
						Description
					</Typography>
					<Typography
						variant='body2'
						overflow="hidden auto"
						/* maxHeight="calc(100vh - 38rem)" */
						sx={{
							color: (theme) => theme.palette.primary.suppressedText,
							maxHeight: {
								xs: 250,
								md: 400
							}
						}}
					>
						{event.description.split(" ").map(i => i.startsWith('http') || i.startsWith('https') ? <a style={{ color: "#ffc50c" }} target="_blank" rel="noreferrer noopener" href={i}>{i}&nbsp;</a> : i + " ")}
					</Typography>
				</Stack>
			</Box>
		</Box>
	);
}

export default Details;
