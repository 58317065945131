import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { Stack } from "@mui/material";
import { withStyles } from "@mui/styles";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const defaultMaterialTheme = createTheme({
	overrides: {
		MuiPickersCalendarHeader: {
			switchHeader: {
				color: "#6A148E",
				textTransform: "uppercase",
			},
			dayLabel: {
				textTransform: "uppercase",
			},
		},
		MuiPickersDay: {
			day: {
				color: "#707070",
			},
			daySelected: {
				backgroundColor: "#6A148E",
				"&:hover": {
					backgroundColor: "#6A148E",
				},
			},
			current: {
				color: "#6A148E",
				borderColor: "#828282",
			},
		},
	},
});

const color = "#999999";

export default function EventDatePicker({ eventTime, onTimeChanged, value }) {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<ThemeProvider theme={defaultMaterialTheme}>
				<DateTimePicker
					label={eventTime}
					value={value}
					onChange={(newValue) => onTimeChanged(eventTime === 'Event Start' ? { start_date: newValue } : { end_date: newValue })}
					renderInput={(params) => (
						<TextField
							{...params}
							id="outlined-basic"
							variant="outlined"
							sx={{
								svg: { color },
								input: { color: "#FFF" },
								label: { color },

								"& label.Mui-focused": {
									color: "white",
								},
								"& .MuiInput-underline:after": {
									borderBottomColor: "#ffc50c",
								},
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										borderColor: "#828282",
									},
									"&:hover fieldset": {
										borderColor: "white",
									},
									"&.Mui-focused fieldset": {
										borderColor: "#ffc50c",
									},
								},
							}}
							fullWidth
						/>
					)}
				/>
			</ThemeProvider>
		</LocalizationProvider>
	);
}
