import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import SilhoutteLoader from './SilhoutteLoader'
import './style.css'

export default function Step({ title, description, innerRef, active }) {
    return (
        <Stack
            spacing={1}
        >
            <Box
                sx={{
                    position: 'relative'
                }}
            >
                <Box
                    ref={innerRef}
                    sx={{
                        py: 1,
                        px: 2,
                        borderRadius: 2,
                        border: '2px solid white',
                        width: '100%',
                    }}
                >
                    <Typography
                        variant='body2'
                        fontSize={22}
                        textTransform='uppercase'
                        textAlign='center'
                    >
                        {title}
                    </Typography>
                </Box>
                {active && <SilhoutteLoader />}
            </Box>
            <Typography
                variant='body2'
                sx={{
                    color: (theme) => theme.palette.primary.suppressedText + "99",
                }}
            >
                {description}
            </Typography>
        </Stack>
    )
}
