import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Tabs from "../Events/ResponsiveTabs";
import BuySell from "./BuySell";
// import TradeChart from "./Chart";
// import Header from "./Header";
import MobileView from "./MobileView/MobileView";
import PriceAmountTotal from "./PriceAmountTotal";
import Spread from "./Spread";
import WebView from "./WebView";

function Trade() {
	return (
		<>
			<Box
				sx={{
					mt: 9,
					display: {
						xs: "none",
						lg: "flex",
					},
					mb: 4,
				}}
			>
				<WebView />
			</Box>
			<Box
				sx={{
					mt: 9,
					display: {
						xs: "flex",
						lg: "none",
					},
					mb: 4,
					width: "100%",
				}}
			>
				<MobileView />
			</Box>
		</>
	);
}

export default Trade;
