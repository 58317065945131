import {
  Avatar,
  CardActionArea,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Avatars from "../Events/Avatars";
import Moment from "react-moment";

export default function OrdersCard({
  date,
  symbol,
  name,
  cryptoCount,
  price,
  cryptoImage,
  transactionType,
  cryptoName,
}) {
  return (
    <CardActionArea
      sx={{
        mb: 1,
      }}
    >
      <Paper
        sx={{
          p: 2,
          background: (theme) => theme.palette.primary.darker /* + "90" */,
        }}
        elevation={10}
      >
        <Stack alignItems="center" justifyContent="space-between" spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.suppressedText,
              }}
              variant="body2"
            >
              Market
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.primary.suppressedText,
              }}
            >
              <Moment format="LLL">{date}</Moment>
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Stack direction="row" spacing={1}>
              <Avatar
                src={cryptoImage}
                sx={{
                  width: 30,
                  height: 30,
                }}
              />
              <Stack>
                <Typography fontWeight="600">
                  {cryptoCount} {symbol}
                  <Typography
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.primary.suppressedText,
                    }}
                  >
                    {cryptoName.toUpperCase()}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.primary.suppressedText,
                    }}
                  >
                    {`$ ${(Number(price) * Number(cryptoCount)).toFixed(8)}`}
                  </Typography>
                </Typography>
              </Stack>
            </Stack>
            <Stack alignItems="end">
              <Typography fontWeight="500">
                $ {Number(Number(price).toFixed(8))}
              </Typography>
              <Typography
                fontWeight="500"
                align="right"
                sx={{
                  color: (theme) =>
                    transactionType.toUpperCase() === "BUY"
                      ? theme.palette.misc.success
                      : theme.palette.misc.error,
                }}
              >
                {transactionType.toUpperCase()}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </CardActionArea>
  );
}
