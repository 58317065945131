import {
  Avatar,
  Box,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function PerformingCoins({ coins }) {
  const [max, setMax] = useState({});

  useEffect(async () => {
    let max = { crypto_share: 0 };
    if (coins)
      for await (let coin of coins) {
        if (Number(coin.crypto_share) > Number(max.crypto_share)) {
          max = coin;
        }
      }
    setMax(max);
  }, [coins]);

  return (
    <Box
      sx={{
        maxHeight: { md: "500px" },
        overflow: "hidden auto",
      }}
    >
      <Typography
        variant="h5"
        fontWeight="500"
        sx={{
          mb: 1,
        }}
      >
        Asset Shares
      </Typography>
      <Stack spacing={2}>
        {coins?.map((coin) => (
          <Box style={{ position: "relative" }} key={coin.crypto_id}>
            <LinearProgress
              variant="determinate"
              value={Number(coin.crypto_share) * 100}
              sx={{
                height: 35,
                borderRadius: 2,
                width: "100%",
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: (theme) =>
                    theme.palette.primary.darker + "80",
                },
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 2,
                  background: (theme) =>
                    max.crypto_id !== coin.crypto_id
                      ? theme.palette.primary.light
                      : `linear-gradient(45deg, ${theme.palette.secondary.dark}, ${theme.palette.secondary.main})`,
                },
              }}
            />
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                position: "absolute",
                top: "18%",
                left: "2%",
              }}
            >
              <Typography variant="body1" fontWeight="500">
                {coin.crypto_name}
              </Typography>
              <Avatar
                src={coin.crypto_image}
                sx={{
                  height: 22,
                  width: 22,
                  backgroundColor: (theme) =>
                    theme.palette.primary.light + "80",
                }}
              />
            </Stack>
            <Typography
              variant="body1"
              fontWeight="500"
              sx={{
                position: "absolute",
                top: "18%",
                left: {
                  md: "94%",
                  xs: "91%",
                },
                transform: "translateX(-50%)",
              }}
            >
              {(Number(coin.crypto_share) * 100).toFixed(2)}%
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
