import ipfs from '../dApp/ipfs'

function add(data) {
    return new Promise(async (resolve, reject) => {
        try {
            if (typeof data === 'object' || Array.isArray(data)) {
                const results = await ipfs.add(JSON.stringify(data))
                if (Array.isArray(data)) {
                    let cids = []
                    for await (const { cid } of results) {
                        cids.push(cid.toString())
                    }
                    resolve(cids)
                }
                resolve(results.cid.toString())
            }
            const { cid } = await ipfs.add(data)
            resolve(cid.toString())
        }
        catch (err) {
            reject(err)
        }
    })
}

function getURI(cid = '') {
    return `ipfs://ipfs/${cid}`
}

function getHttpURI(cid = '') {
    return `https://${process.env.REACT_APP_IPFS_HOST}/ipfs/${cid}`
}

export default { add, getURI, getHttpURI }