import { Avatar, Box, Button, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Dapp from '../../dApp/erc20.js'
import Web3Utils from '../../utils/web3.utils'
import SupportedNetworks from '../Modals/Network/SupportedNetworks'
import Circular from '../ProgressBars/Circular'
import FormDialog from '../CreateEvent'

export default function ResponsiveAccountDetails({
    network
}) {
    const [modal, setModal] = useState(null)
    const [open, setOpen] = useState(true);

    // useEffect(async () => {
    //     setBalance(await Web3Utils.getBalance())
    // }, [])

    return (
        <>
            {modal}
            <Box>
                <Stack
                    direction='row'
                    spacing={2}
                >
                    {/* <Button
                        disableElevation
                        sx={{
                            letterSpacing: 1,
                            fontSize: {
                                md: 16
                            },
                            background: (theme) => theme.palette.primary.light + "90"
                        }}
                        variant='contained'
                        startIcon={
                            <Avatar
                                src={Dapp['mumbai']['image']}
                                sx={{
                                    width: 20,
                                    height: 20
                                }}
                            />
                        }
                        onClick={() => setModal(
                            <SupportedNetworks
                                open
                                onClose={() => setModal(null)}
                            />
                        )}
                    >
                        {network}
                    </Button> */}
                    <Button
                        disableElevation
                        className='create-event-btn'
                        variant='contained'
                        href='/create'
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontSize: {
                                    xs: 13,
                                    md: 16
                                },
                            }}
                            color='#fff'
                            align='center'
                            fontWeight={500}
                        >
                            Create Event
                        </Typography>
                    </Button>
                </Stack>
            </Box>
        </>
    )
}
