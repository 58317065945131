import React from 'react'
import Base from '../Base'
import Wallet from '../../../wallet/config.json'
import { Avatar, Stack, Typography } from '@mui/material'

export default function WalletConnected({
    open,
    onClose,
    wallet
}) {
    return (
        <Base
            open={open}
            onClose={onClose}
        >
            <Stack
                spacing={2}
                direction='row'
                alignItems='center'
            >
                <Avatar
                    src='/assets/check.png'
                    sx={{
                        width: 30,
                        height: 30
                    }}
                />
                <Typography
                    variant='button'
                    sx={{
                        fontSize: 22
                    }}
                >
                    Connected to {Wallet[wallet]['name']}
                </Typography>
            </Stack>
        </Base>
    )
}
