import { Avatar, Box, Typography, IconButton, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "../EventDetails/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import ExpandLessSharpIcon from "@mui/icons-material/ExpandLessSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";

function Cryptos({
  data,
  setSelected,
  selected,
  loading,
  toggleSideDrawer,
  height,
}) {
  const { width, i } = useWindowDimensions();

  const [searchItem, setSearchItem] = useState(null);

  const [isExpanded, setExpanded] = useState(false);

  const [searchedResults, setSearchedResults] = useState(data);

  const backClicked = () => {
    if (width < 1200) {
      toggleSideDrawer();
    }
  };

  useEffect(() => {
    if (searchItem) {
      const searchedResults = data.filter((item) => {
        return (
          item.crypto_symbol.toLowerCase().includes(searchItem.toLowerCase()) ||
          item.crypto_name.toLowerCase().includes(searchItem.toLowerCase())
        );
      });
      setSearchedResults(searchedResults);
    } else {
      setSearchedResults(data);
    }
  }, [data]);

  const onExpandIconClicked = () => {
    setExpanded((expand) => !expand);
    setSearchedResults(data);
    setSearchItem(null);
  };

  const handleSearch = (e) => {
    const searchItem = e.target.value;
    setSearchItem(searchItem);
    if (searchItem) {
      const searchedResults = data.filter((item) => {
        console.log("Item ", item.crypto_name);
        return (
          item.crypto_symbol.toLowerCase().includes(searchItem.toLowerCase()) ||
          item.crypto_name.toLowerCase().includes(searchItem.toLowerCase())
        );
      });
      setSearchedResults(searchedResults);
    } else {
      setSearchedResults(data);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #553398",
          width: "100%",
          height: height ? height : "35vh",
        }}
      >
        {width < 1200 && (
          <Box
            m={1} //margin
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <IconButton onClick={backClicked}>
              <ArrowBackIcon />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: 1.5,
            py: 1,
            border: "1px solid #55339855",
            pr: 2.2,
            backgroundColor: (theme) => theme.palette.primary.drawer,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // ml: 1,
              width: "30%",
            }}
          >
            <Typography variant="body1" sx={{ fontSize: 13 }}>
              Symbol
            </Typography>
            {!isExpanded ? (
              <IconButton onClick={onExpandIconClicked}>
                <SearchSharpIcon sx={{ fontSize: 15 }} />
              </IconButton>
            ) : (
              <IconButton onClick={onExpandIconClicked}>
                <ExpandLessSharpIcon sx={{ fontSize: 15 }} />
              </IconButton>
            )}
          </Box>
          <Typography
            variant="body1"
            sx={{ width: "30%", textAlign: "right", fontSize: 13 }}
          >
            Price
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: "20%",
              textAlign: "right",
              fontSize: 13,
              ml: 1,
            }}
          >
            24h%
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: "15%",
              textAlign: "right",
              ml: 1,
              fontSize: 13,
            }}
          >
            7d%
          </Typography>
        </Box>
        {isExpanded && (
          <TextField
            id="standard-search"
            placeholder="Search your token"
            type="search"
            variant="standard"
            sx={{
              pl: 1,
            }}
            onChange={(e) => handleSearch(e)}
            size="small"
          />
        )}
        <Box
          sx={{
            overflowY: "scroll",
          }}
        >
          {searchedResults.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: 1.5,
                  py: 1,
                  my: 0.3,
                  backgroundColor: (theme) =>
                    selected.crypto_id === item.crypto_id
                      ? theme.palette.primary.light + "50"
                      : "",
                  cursor: "pointer",
                  border: "1px solid #55339855",
                  boxShadow: "0px 3px 3px #0007",
                  "&:hover": {
                    backgroundColor: (theme) =>
                      theme.palette.primary.light + "50",
                  },
                }}
                onClick={() => {
                  if (width < 1200) {
                    toggleSideDrawer();
                  }
                  setSelected(item);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // ml: 1,
                    width: "30%",
                  }}
                >
                  <Avatar
                    src={item.crypto_image}
                    sx={{
                      height: 20,
                      width: 20,
                      marginRight: 1,
                      p: 0.1,
                      background: (theme) => theme.palette.primary.darker,
                    }}
                  />
                  <Typography
                    variant="body1"
                    fontWeight="500"
                    sx={{ fontSize: 13 }}
                  >
                    {item.crypto_symbol}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{ width: "30%", textAlign: "right", fontSize: 13 }}
                >
                  $ {item.crypto_current_price}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    width: "20%",
                    textAlign: "right",
                    fontSize: 13,
                    ml: 1,
                    color: (theme) =>
                      item.crypto_price_change_percent_24h > 0
                        ? theme.palette.misc.success
                        : theme.palette.misc.error,
                  }}
                >
                  {Math.abs(
                    Number(
                      Number(item.crypto_price_change_percent_24h).toFixed(2)
                    )
                  )}
                  %
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    width: "15%",
                    textAlign: "right",
                    ml: 1,
                    fontSize: 13,
                    color: (theme) =>
                      item.crypto_price_change_percent_7d > 0
                        ? theme.palette.misc.success
                        : theme.palette.misc.error,
                  }}
                >
                  {Math.abs(
                    Number(
                      Number(item.crypto_price_change_percent_7d).toFixed(2)
                    )
                  )}
                  %
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
}

export default Cryptos;
