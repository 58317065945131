import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  CardActionArea,
  Slider,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Base from "./Base";
import StyledTextField from "../../CreateEventNew/StyledTextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Reducers from "../../../API/Reducers";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { LoadingButton } from "@mui/lab";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import TransactionConfirmation from "../../Modals/Transaction/TransactionConfirmation";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const USDC_IMG =
  "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389";
const MAX_DECIMALS = 9;
const MAX_RADIX = 6;

export default function Sell({
  crypto,
  vestibule_id,
  tradable_coins,
  onCoinSwitched,
  onTransactionCompleted,
  fetching,
  open: sellOpen,
}) {
  const [internalCrypto, setInternalCrypto] = useState({});
  const [balances, setBalances] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiCallLoad, setApiCallLoad] = useState(false);
  const [completed, setCompleted] = useState(null);
  const [usdPrice, setUsdPrice] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [accepted, setAccepted] = useState(true);
  const usdcRef = useRef();
  const cryptoRef = useRef();
  const [transactionConfirmation, setTransactionConfirmation] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);

  function truncate(number = 0, precision = 2) {
    return Math.floor(Number(number) * 10 ** precision) / 10 ** precision;
  }

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  useEffect(() => {
    if (
      internalCrypto.id === crypto.id &&
      !transactionConfirmation &&
      sellOpen
    ) {
      if (
        internalCrypto.market_data.current_price.usd !==
        crypto.market_data.current_price.usd
      ) {
        setAccepted(false);
      }
    } else {
      setInternalCrypto(crypto);
    }
  }, [crypto]);

  useEffect(() => {
    //calculateUsdPrice(quantity);
    //calculateFunds(quantity);
    if (quantity) {
      handleQuantityChange(quantity);
    }
  }, [internalCrypto]);

  useEffect(() => {
    if (accepted) setInternalCrypto(crypto);
  }, [accepted]);

  useEffect(() => {
    async function getBalances() {
      setUsdPrice(0);
      if (!loading) setLoading(true);
      var response = await Reducers.getCryptoBalance({
        vestibule_id,
        crypto_id: crypto?.id,
      });
      setBalances({
        current_value_in_usd: truncate(Number(response.current_value_in_usd)),
        funds: truncate(Number(response.current_value_in_usd)),
        assets_value_in_usd: truncate(Number(response.assets_value_in_usd)),
        assets: truncate(Number(response.assets_value_in_usd)),
        crypto_balance: truncate(response.crypto_balance, MAX_DECIMALS),
        asset_percent: 0,
        funds_percent: 0,
        max: 0,
      });
      setLoading(false);
    }

    if (internalCrypto?.id !== crypto.id) getBalances();
  }, [completed, crypto]);

  const openTransactionConfirmation = () => {
    setTransactionConfirmation(true);
  };

  const closeTransactionConfirmation = () => {
    setTransactionConfirmation(false);
  };

  const handleSellClicked = async () => {
    try {
      if (completed) setCompleted(false);
      setApiCallLoad(true);
      await Reducers.postSell({
        vestibule_id,
        crypto_id: internalCrypto?.id,
        crypto_quantity: quantity,
        crypto_current_price: String(
          internalCrypto?.market_data?.current_price?.usd
        ),
      });

      setTransactionConfirmation(false);
      onTransactionCompleted();

      setApiCallLoad(false);
      setCompleted("success");
      setQuantity("");
      setBalances(null);
      setTimeout(() => {
        setCompleted(null);
      }, 1500);
    } catch (err) {
      console.log(err);
      setApiCallLoad(false);
      setCompleted("failed");
      setQuantity("");
      setBalances(null);
      setTimeout(() => {
        setCompleted(null);
      }, 1500);
    }
  };

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const CurrencySelector = ({ image, currency, value, open }) => {
    return (
      <Box
        sx={{
          background: (theme) => (!open ? "#2E2E2E" : theme.palette.misc.error),
          p: 2,
          minWidth: {
            md: "12rem",
            xs: "10rem",
          },
          // height: "76px",
          height: {
            xs: "50%",
            xl: "100%",
          },
          "&:hover": {
            background: (theme) =>
              !open ? "#373737" : theme.palette.misc.error + "99",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: !open ? "space-between" : "center",
          transition: "0.3s ease all",
        }}
        className={`${currency !== "USD" && "currency-list-btn"}`}
        onClick={(e) => matches && setOpen(!open)}
      >
        {!open ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar
                src={image}
                sx={{
                  height: 35,
                  width: 35,
                }}
              />
              <Stack>
                <Typography fontSize={16} fontWeight="bold" color="white">
                  {currency}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: (theme) => theme.palette.primary.suppressedText,
                  }}
                >
                  {value} USD
                </Typography>
              </Stack>
            </Stack>
            {matches && currency !== "USD" && <KeyboardArrowDownIcon />}
          </Stack>
        ) : (
          <CloseIcon fontSize="large" />
        )}
        <Box className={`currency-menu ${open && "show"}`}>
          <Stack
            divider={<Divider />}
            sx={{
              overflow: "hidden auto",
              height: "14rem",
            }}
          >
            {tradable_coins
              ?.filter((i) => i.crypto_id !== internalCrypto?.id)
              ?.map((coin) => (
                <CardActionArea
                  sx={{
                    py: 1,
                    px: 2,
                  }}
                  onClick={() => {
                    onCoinSwitched(coin);
                    setQuantity("");
                    setAccepted(true);
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar
                      src={coin?.crypto_image}
                      sx={{
                        height: 35,
                        width: 35,
                      }}
                    />
                    <Stack>
                      <Typography
                        textTransform="uppercase"
                        fontSize={16}
                        fontWeight="bold"
                        color="white"
                      >
                        {coin?.crypto_symbol}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: (theme) =>
                            theme.palette.primary.suppressedText,
                        }}
                      >
                        {coin?.crypto_current_price} USD
                      </Typography>
                    </Stack>
                  </Stack>
                </CardActionArea>
              ))}
          </Stack>
        </Box>
      </Box>
    );
  };

  const handleQuantityChange = (value) => {
    if (value >= 0 && Number(value) <= Number(getMaxValidQuantity())) {
      setErrorOccured(false);
      setQuantity(value);
      calculateUsdPrice(value);
      calculateFunds(value);
    } else {
      setErrorOccured(true);
      setQuantity(0);
      calculateUsdPrice(value);
      calculateFunds(0);
    }
  };
  const calculateAssetPrice = (funds) => {
    const rate = 1 / Number(internalCrypto?.market_data?.current_price?.usd);
    const currentPrice = internalCrypto?.market_data?.current_price?.usd;
    cryptoRef.current.value = (rate * Number(funds)).toFixed(9);
  };

  const handleAssetChange = (value) => {
    if (
      value >= 0 &&
      Number(value) <=
        Number(internalCrypto?.market_data?.current_price?.usd) *
          Number(getMaxValidQuantity())
    ) {
      setErrorOccured(false);
      calculateAssetPrice(value);
      setQuantity(cryptoRef.current.value);
      calculateFunds(cryptoRef.current.value);
    } else {
      setErrorOccured(true);
      calculateAssetPrice(value);
      setQuantity(0);
      calculateFunds(0);
    }
  };

  const getMaxValidQuantity = () => {
    console.log("crypto_balance ", balances?.crypto_balance);
    return balances?.crypto_balance <= 0 ? 0 : balances?.crypto_balance;
  };

  const getLessThanValue = (value = "") => {
    if (!isLessThanValue(value)) {
      if (value.includes(".")) {
        var decimals = value.substring(value.indexOf(".") + 1, value.length);
        decimals = decimals.slice(0, MAX_DECIMALS);
        value = value.substring(0, value.indexOf(".")).slice(0, MAX_RADIX);
        value += "." + decimals;
      } else {
        value = value.slice(0, MAX_RADIX);
      }
    }
    return value;
  };

  const isLessThanValue = (value = "") => {
    if (value.includes(".")) {
      var decimals = value.substring(value.indexOf(".") + 1, value.length);
      var radix = value.substring(0, value.indexOf("."));
      if (decimals.length < MAX_DECIMALS && radix.length < MAX_RADIX)
        return true;
    } else {
      return value.length < MAX_RADIX;
    }
    return false;
  };

  const trim = (value = "") => {
    if (value.includes("e")) {
      value = Number(value).toFixed(MAX_DECIMALS);
    }
    if (value.includes(".")) {
      var decimals = value.substring(value.indexOf(".") + 1, value.length);
      decimals = decimals.slice(0, MAX_DECIMALS);
      let index;
      for (let i = decimals.length - 1; i >= 0; i--) {
        if (decimals.charAt(i) !== "0") break;
        else index = i;
      }
      decimals = decimals.substring(0, index);
      value = value.substring(0, value.indexOf("."));
      if (decimals.length > 0) value += "." + decimals;
    }
    return value;
  };

  const calculateUsdPrice = (quantity) => {
    const current_price = internalCrypto?.market_data?.current_price?.usd;
    const price_billed = Number(current_price) * Number(quantity);
    if (usdcRef.current) {
      usdcRef.current.value = (
        Math.round((price_billed + Number.EPSILON) * 100) / 100
      ).toFixed(MAX_DECIMALS);
      setUsdPrice(usdcRef.current.value);
    }
  };

  const handleSliderChange = (event, newValue) => {
    setErrorOccured(false);
    const usdc_value =
      Number(internalCrypto?.market_data?.current_price?.usd) *
      getMaxValidQuantity() *
      Number(newValue);
    usdcRef.current.value = (usdc_value / 100).toFixed(9);
    setUsdPrice(usdcRef.current.value);
    calculateAssetPrice(usdc_value / 100);
    setQuantity(cryptoRef.current.value);
    calculateFunds(cryptoRef.current.value);
  };

  const calculateFunds = (quantity) => {
    const current_price = internalCrypto?.market_data?.current_price?.usd;
    let price_billed = Number(current_price) * Number(quantity);
    price_billed = Math.round((price_billed + Number.EPSILON) * 100) / 100;
    const new_funds = Number(balances?.current_value_in_usd) + price_billed;

    //percentages
    let asset_percent;
    if (balances?.assets_value_in_usd == 0 && price_billed > 0)
      asset_percent = "100";
    else
      asset_percent =
        (Number(price_billed) / Number(balances?.assets_value_in_usd)) * 100 >
        100
          ? "-100"
          : trim(
              String(
                (-Number(price_billed) /
                  Number(balances?.assets_value_in_usd)) *
                  100
              )
            );

    let funds_percent = 0;
    if (balances?.current_value_in_usd == 0)
      funds_percent = Number(price_billed) * 100;
    else
      funds_percent = trim(
        String(
          (Number(price_billed) / Number(balances?.current_value_in_usd)) * 100
        )
      );

    setBalances({
      ...balances,
      funds: new_funds.toFixed(2),
      assets:
        Number(balances?.assets_value_in_usd) - price_billed <= 0
          ? "0"
          : truncate(
              Number(
                String(
                  Math.abs(Number(balances?.assets_value_in_usd) - price_billed)
                )
              )
            ),
      asset_percent,
      funds_percent,
    });
  };

  return (
    <Box
      sx={{
        px: 3,
        pb: 3,
        height: "100%",
      }}
      className="transaction"
    >
      <TransactionConfirmation
        open={transactionConfirmation}
        onClose={closeTransactionConfirmation}
        onConfirmation={handleSellClicked}
        data={{
          usdPrice: usdcRef.current ? usdcRef.current.value : 0,
          symbol: internalCrypto?.symbol,
          quantity,
          image: internalCrypto?.image?.large,
          current_price: internalCrypto?.market_data?.current_price?.usd,
          asset_percent: balances?.asset_percent,
        }}
        type="Sell"
        loading={apiCallLoad}
      />
      {!loading ? (
        /* balances?.crypto_balance <= 0 ?
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography
                            fontWeight='500'
                            fontSize={18}
                        >
                            You have no {internalCrypto?.symbol?.toUpperCase()} to sell. Buy some {internalCrypto?.symbol?.toUpperCase()} to sell later.
                        </Typography>
                    </Box>
                    : */
        <Box
          sx={{
            mt: 3,
          }}
        >
          <Stack spacing={3} pt={2}>
            <Stack alignItems="end" justifyContent="center">
              <Stack
                sx={{
                  width: "100%",
                }}
                spacing={0.5}
              >
                <Typography
                  sx={{
                    //fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  YOU RECEIVE
                </Typography>
                <StyledTextField
                  variant="outlined"
                  placeholder={`0.00 USD`}
                  fullWidth
                  //value={usdPrice}
                  type="number"
                  inputRef={usdcRef}
                  onChange={(e) => handleAssetChange(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          p: 2,
                          minWidth: {
                            md: "12rem",
                            xs: "10rem",
                          },
                          height: "76px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: !open ? "space-between" : "center",
                          transition: "0.3s ease all",
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Avatar
                              src={USDC_IMG}
                              sx={{
                                height: 35,
                                width: 35,
                              }}
                            />
                            <Stack>
                              <Typography
                                fontSize={16}
                                fontWeight="bold"
                                color="white"
                              >
                                USD
                              </Typography>
                              {/* <Typography
                                variant="caption"
                                sx={{
                                  color: (theme) =>
                                    theme.palette.primary.suppressedText,
                                }}
                              >
                                {Number(usdPrice).toLocaleString()} USD 
                              </Typography> */}
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    ),
                    sx: {
                      pr: 0,
                      fontSize: {
                        xs: 18,
                        md: 20,
                      },
                      background: "#1F1F1F",
                      fontWeight: "500",
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "white",
                        cursor: "not-allowed",
                      },
                    },
                  }}
                  sx={{
                    "& label.Mui-focused": {
                      color: "none",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "none",
                      },
                      fieldset: {
                        border: "1px solid #80808050",
                        borderRadius: 2,
                      },
                    },
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  height: "60px",
                }}
                alignItems="end"
              >
                <Box
                  sx={{
                    width: "90%",
                    borderLeft: "1px solid #808080",
                    height: "30px",
                  }}
                  className="transaction-base"
                ></Box>
                <Stack
                  sx={{
                    pl: 2,
                    width: {
                      md: "94.5%",
                      xs: "97.5%",
                    },
                  }}
                  direction="row"
                  spacing={2}
                >
                  <InfoOutlinedIcon fontSize="small" />
                  <Typography
                    variant="caption"
                    sx={{
                      color: (theme) => theme.palette.primary.suppressedText,
                    }}
                  >
                    Rate:{" "}
                    {(
                      1 /
                      Number(internalCrypto?.market_data?.current_price?.usd)
                    ).toFixed(9)}{" "}
                    {internalCrypto?.symbol?.toUpperCase()} &nbsp;&nbsp; |
                    &nbsp;&nbsp; Check total price below
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    width: "90%",
                    borderLeft: "1px solid #808080",
                    height: "30px",
                  }}
                  className="transaction-base"
                ></Box>
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                }}
                spacing={0.5}
              >
                <Typography
                  sx={{
                    //fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  YOU SELL
                </Typography>
              </Stack>
              <StyledTextField
                variant="outlined"
                //label="YOU SELL"
                placeholder={`0.00 ${internalCrypto?.symbol.toUpperCase()}`}
                //value={balances?.crypto_balance <= 0 ? "0" : quantity}
                onChange={(e) => handleQuantityChange(e.target.value)}
                fullWidth
                autoFocus
                disabled={balances?.crypto_balance <= 0}
                //helperText={balances?.crypto_balance <= 0 ? `Not enough ${internalCrypto?.symbol?.toUpperCase()} to sell` : `Maximum valid quantity : ${getMaxValidQuantity()} ${internalCrypto?.symbol.toUpperCase()}`}
                type="number"
                inputRef={cryptoRef}
                helperText={errorOccured && "Not enough assets to sell"}
                error={errorOccured}
                InputProps={{
                  endAdornment: (
                    <CurrencySelector
                      image={internalCrypto?.image?.large}
                      open={open}
                      currency={internalCrypto?.symbol?.toUpperCase()}
                      value={Number(
                        Number(
                          internalCrypto?.market_data?.current_price?.usd
                        ).toFixed(8)
                      )}
                    />
                  ),
                  sx: {
                    pr: 0,
                    fontSize: {
                      xs: 18,
                      md: 20,
                    },
                    fontWeight: "500",
                    "& .MuiInputBase-input.Mui-disabled": {
                      border: "none",
                      "& hover fieldset": {
                        border: "none",
                      },
                    },
                  },
                }}
                /* sx={{
                                "& label.Mui-focused": {
                                    color: "none",
                                },
                                "& .MuiInput-underline:after": {
                                    borderBottomColor: "none",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: "none",
                                    },
                                },
                            }} */
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                mt={1}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: (theme) => theme.palette.primary.suppressedText,
                  }}
                >
                  {balances?.crypto_balance <= 0
                    ? `Not enough balance to sell minimum 0.00000001 ${internalCrypto?.symbol?.toUpperCase()}`
                    : `Maximum valid quantity : ${getMaxValidQuantity()} ${internalCrypto?.symbol.toUpperCase()}`}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: (theme) => theme.palette.primary.suppressedText,
                  }}
                >
                  *Prices are normalized accurate to real-world prices.
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"} justifyContent={"center"}>
              <Slider
                aria-label="Restricted values"
                defaultValue={0}
                step={25}
                marks={marks}
                valueLabelDisplay="auto"
                onChange={handleSliderChange}
                color="secondary"
                sx={{
                  width: "85%",
                }}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: (theme) => theme.palette.primary.suppressedText,
                    }}
                  >
                    Assets
                  </Typography>
                  {balances?.asset_percent != 0 &&
                    !isNaN(balances?.asset_percent) && (
                      <Stack direction="row" alignItems="center">
                        {Number(balances?.asset_percent) < 0 ? (
                          <ArrowDropDownIcon
                            fontSize="small"
                            sx={{
                              color: (theme) => theme.palette.misc.error,
                            }}
                          />
                        ) : (
                          <ArrowDropUpIcon
                            fontSize="small"
                            sx={{
                              color: (theme) => theme.palette.misc.success,
                            }}
                          />
                        )}
                        <Typography
                          variant="caption"
                          sx={{
                            color: (theme) =>
                              Number(balances?.asset_percent) < 0
                                ? theme.palette.misc.error
                                : theme.palette.misc.success,
                          }}
                        >
                          {Math.abs(Number(balances?.asset_percent).toFixed(2))}
                          %
                        </Typography>
                      </Stack>
                    )}
                </Stack>
                <Typography fontWeight="500">{balances?.assets} USD</Typography>
              </Stack>
              <Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: (theme) => theme.palette.primary.suppressedText,
                    }}
                  >
                    Funds
                  </Typography>
                  {balances?.funds_percent != 0 && (
                    <Stack direction="row" alignItems="center">
                      {Number(balances?.funds_percent) < 0 ? (
                        <ArrowDropDownIcon
                          fontSize="small"
                          sx={{
                            color: (theme) => theme.palette.misc.error,
                          }}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          fontSize="small"
                          sx={{
                            color: (theme) => theme.palette.misc.success,
                          }}
                        />
                      )}
                      <Typography
                        variant="caption"
                        sx={{
                          color: (theme) =>
                            Number(balances?.funds_percent) < 0
                              ? theme.palette.misc.error
                              : theme.palette.misc.success,
                        }}
                      >
                        {Math.abs(Number(balances?.funds_percent).toFixed(2))}%
                      </Typography>
                    </Stack>
                  )}
                </Stack>
                <Typography fontWeight="500">{balances?.funds} USD</Typography>
              </Stack>
            </Stack>
            {!accepted && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderRadius: 2,
                  background: "#2E2E2E",
                  py: 1.2,
                  px: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: {
                      xs: 14,
                      md: 16,
                    },
                  }}
                >
                  Prices updated.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    "&:hover": {
                      background: (theme) => theme.palette.primary.light + "aa",
                    },
                    borderRadius: 2,
                  }}
                  onClick={() => setAccepted(true)}
                  disableElevation
                >
                  <Typography
                    fontWeight={500}
                    color="#fff"
                    sx={{
                      fontSize: {
                        xs: 14,
                        md: 16,
                      },
                    }}
                  >
                    Accept
                  </Typography>
                </Button>
              </Stack>
            )}
            <Stack spacing={0.5}>
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.primary.suppressedText,
                }}
                align="center"
              >
                All values displayed above are approximates. Please DO NOT
                assume them to be accurate.
              </Typography>
              <LoadingButton
                fullWidth
                variant="contained"
                disabled={
                  quantity <= 0 || balances?.crypto_balance <= 0 || !accepted
                }
                loading={fetching || transactionConfirmation}
                loadingIndicator={
                  fetching ? (
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <CircularProgress
                        size={20}
                        thickness={8}
                        sx={{
                          color: (theme) =>
                            theme.palette.primary.suppressedText,
                        }}
                      />
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        sx={{
                          color: (theme) =>
                            theme.palette.primary.suppressedText,
                        }}
                      >
                        Fetching latest prices
                      </Typography>
                    </Stack>
                  ) : (
                    <CircularProgress
                      size={20}
                      thickness={8}
                      sx={{
                        color: (theme) => theme.palette.primary.suppressedText,
                      }}
                    />
                  )
                }
                sx={{
                  height: "3rem",
                  background: (theme) => theme.palette.primary.light + "90",
                }}
                onClick={openTransactionConfirmation}
              >
                {!completed ? (
                  balances?.crypto_balance <= 0 ? (
                    `NOT ENOUGH ${internalCrypto?.symbol?.toUpperCase()} TO SELL`
                  ) : (
                    "SELL"
                  )
                ) : completed === "success" ? (
                  <CheckCircleRoundedIcon
                    fontSize="large"
                    sx={{
                      color: (theme) => theme.palette.misc.success,
                    }}
                  />
                ) : (
                  <CancelRoundedIcon
                    fontSize="large"
                    sx={{
                      color: (theme) => theme.palette.misc.error,
                    }}
                  />
                )}
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <CircularProgress thickness={8} size={30} color="secondary" />
        </Box>
      )}
    </Box>
  );
}
