import { Avatar, Box, Paper, Stack, Typography, Drawer } from "@mui/material";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ResponsiveFilter from "./ResponsiveFilter";
import Reducers from "../../API/Reducers";
import ResponsiveLoading from "./ResponsiveLoading";
import ResponsiveTabs from "./ResponsiveTabs";
import PopularEvents from "./Categories/PopularEvents";
import UpcomingEvents from "./Categories/UpcomingEvents";
import PastEvents from "./Categories/PastEvents";
import { AnimatePresence, motion } from "framer-motion";
import EventDetails from "../EventDetails";
import { useWindowDimensions } from "../EventDetails/constants";
import StorageKeys from "../../storage.keys";
import "./style.css";
import JoinEventSteps from "../Modals/JoinEvent/JoinEventSteps";
import Contracts from "../../functions/contract.functions.js";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import ERC20 from "../../dApp/erc20.js";
import JoiningCompleted from "../Modals/JoinEvent/JoiningCompleted";
import BasicLoader from "../Modals/BasicLoader";
import { useStorage } from "../../hooks/storage.hooks";
import { PriceChange } from "@mui/icons-material";

export default function Events() {
  const [events, setEvents] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});

  const [modal, setModal] = useState({
    open: false,
    error: false,
  });
  const [completed, setCompleted] = useState(false);

  const [filters, setFilters] = useState({});

  const navigate = useNavigate();

  const approvalRef = useRef();
  const smartContractRef = useRef();
  const joiningEventRef = useRef();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const { width, height } = useWindowDimensions();

  const handleEventClicked = (event) => {
    setSelectedEvent(event);
    toggleDrawer();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { active, account } = useWeb3React();

  const [user_id] = useStorage(StorageKeys.USER_ID);

  useLayoutEffect(() => {
    console.log(modal.open, "open");
    if (!modal.open) {
      if (!isLoading) setLoading(true);
      async function get() {
        console.log("Filters", filters);
        var response = await Reducers.getAllEvents(
          { filters },
          {
            user_id,
          }
        );
        console.log("Response ", response);
        setEvents(response);
        setLoading(false);
      }
      console.log("called");
      //setTimeout(() => {
      get();
      //}, 500)
    }
  }, [active, filters, modal.open, user_id]);

  const handleTabsChanged = (index) => {
    setTab(index);
  };

  /* const [modalActive, setModalActive] = useState(0)

	const onApprovalSuccess = () => {
		if (approvalRef.current) {
			approvalRef.current.style.background = "#46E7A5";
			approvalRef.current.style.borderColor = "#46E7A5";
			approvalRef.current.firstChild.innerHTML = "Assets Approved";
		}
		setModalActive(1)
	}

	const onValidateContractSuccess = () => {
		if (smartContractRef.current) {
			smartContractRef.current.style.background = "#46E7A5";
			smartContractRef.current.style.borderColor = "#46E7A5";
			smartContractRef.current.firstChild.innerHTML = "Smart contract validated";
		}
		setModalActive(2)
	}

	const onJoiningEventSuccess = () => {
		if (joiningEventRef.current) {
			joiningEventRef.current.style.background = "#46E7A5";
			joiningEventRef.current.style.borderColor = "#46E7A5";
			joiningEventRef.current.firstChild.innerHTML = "Joined Successfully";
		}
		setModalActive(3)
	}

	const [joinCompletedModal, setJoinCompletedModal] = useState(false)

	const onJoiningCompleted = () => {
		setJoinCompletedModal(true)
		setTimeout(() => {
			setJoinCompletedModal(false)
		}, 2000)
	}

	React.useEffect(() => {
		setModalActive(0)
	}, [completed]) */

  const [currEvent, setCurrEvent] = useState({});

  const handleJoinClicked = async (event) => {
    setCompleted(false);
    const {
      contract_address,
      event_id,
      joining_fees_in_wei,
      asset,
      vestibule_id,
      is_joined,
      status,
    } = event;
    setCurrEvent(event);
    try {
      if (!is_joined) {
        setModal({ open: true, error: false });
        await Contracts.joinTournament(
          {
            contract_address,
            joining_fees_in_wei,
            asset,
            isNativeAsset: asset === "matic",
          },
          ({ stage }) => {
            /* switch (stage) {
						case 1:
							setTimeout(() => {
								onApprovalSuccess()
							}, 100)
							break;
						case 2:
							onValidateContractSuccess()
							break;
					} */
          }
        );

        const body = {
          user_id: sessionStorage.getItem(StorageKeys.USER_ID),
          event_id,
        };

        await Reducers.joinEvent(body);
        //onJoiningEventSuccess()
        setCompleted(true);
        //setModal({ open: true, error: false })

        //onJoiningCompleted()
      } else {
        if (status === "ongoing")
          navigate(`/event/${event_id}/${vestibule_id}`);
      }
    } catch (err) {
      //setModalActive(0)
      setCompleted(true);
      setModal({ open: true, error: err });
    }
    if (drawerOpen) toggleDrawer();
  };

  const handleModalClose = () => {
    if (completed) setModal({ ...modal, open: false });
  };

  const filterBuilder = (
    alias,
    new_filters,
    props,
    decimals,
    price,
    queryFilters
  ) => {
    for (let prop of props) {
      let value = (new_filters[prop].min * 10 ** Number(decimals)) / price;

      // if (!(`Decimals-${decimals}` in queryFilters[prop])) {
      queryFilters[prop][alias] = {
        min: value,
      };
      // }
      // let minValue = queryFilters[prop][`Decimals-${decimals}`].min;
      // if (value < minValue) {
      //   queryFilters[prop][`Decimals-${decimals}`].min = value;
      // }

      if (new_filters[prop].max) {
        let value = (new_filters[prop].max * 10 ** Number(decimals)) / price;
        // if (
        //   !queryFilters[prop][`Decimals-${decimals}`].max ||
        //   value > queryFilters[prop][`Decimals-${decimals}`].max
        // ) {
        queryFilters[prop][alias].max = value;
        // }
      }
    }

    console.log("Modified filters ", queryFilters);
  };

  const onFiltersSubmit = async (new_filters) => {
    // const baseUrl=''
    console.log("new filters", new_filters);
    const filters = { ...new_filters };
    const queryFilters = {};
    queryFilters.asset = [...new_filters.asset];
    queryFilters.participants = { ...new_filters.participants };
    queryFilters.joining_fees_in_wei = {};
    queryFilters.current_pool_amount = {};
    queryFilters.initial_pool_in_wei = {};
    let query = "";
    if (new_filters.asset.length > 0) {
      for (let i in new_filters.asset) {
        let asset = new_filters.asset[i];
        switch (asset) {
          case "matic": {
            query += "matic-network";
            break;
          }

          case "dai": {
            query += "dai";
            break;
          }

          case "usdc": {
            query += "usd-coin";
            break;
          }

          case "weth": {
            query += "weth";
            break;
          }

          case "wbtc": {
            query += "wrapped-bitcoin";
            break;
          }

          case "usdt": {
            query += "tether";
            break;
          }
        }

        if (i < new_filters.asset.length - 1) {
          query += ",";
        }
      }
      query += "&&vs_currencies=usd";

      let coinPrices = await fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=${query}`
      ).then((res) => res.json());
      console.log("Coin Prices ", coinPrices);

      Object.keys(coinPrices).forEach((key) => {
        let price = coinPrices[key]["usd"];
        let alias =
          ERC20[process.env.REACT_APP_SUPPORTED_TESTNETS]["alias"][key];
        console.log(
          ERC20[process.env.REACT_APP_SUPPORTED_TESTNETS]["alias"][key]
        );
        let decimals =
          ERC20[process.env.REACT_APP_SUPPORTED_TESTNETS]["assets"][
            ERC20[process.env.REACT_APP_SUPPORTED_TESTNETS]["alias"][key]
          ]["decimals"];

        if (decimals === 18 && key === "matic-network") {
          let value =
            (new_filters["joining_fees_in_wei"].min * 10 ** Number(decimals)) /
            price;
          console.log("Price", price, value);
        }

        console.log("new filters ", new_filters);
        filterBuilder(
          alias,
          new_filters,
          ["joining_fees_in_wei", "current_pool_amount", "initial_pool_in_wei"],
          decimals,
          price,
          queryFilters
        );

        // if (!(`Decimals-${decimals}` in new_filters.joining_fees_in_wei)) {
        //   queryFilters.joining_fees_in_wei[`Decimals-${decimals}`] = {
        //     min: value,
        //   };
        // }
        // let minValue =
        //   queryFilters.joining_fees_in_wei[`Decimals-${decimals}`].min;
        // if (value < minValue) {
        //   queryFilters.joining_fees_in_wei[`Decimals-${decimals}`].min = value;
        // }

        // if (new_filters.joining_fees_in_wei[`Decimals-${decimals}`].max) {
        //   let value =
        //     (new_filters.joining_fees_in_wei[`Decimals-${decimals}`].max *
        //       10 ** Number(decimals)) /
        //     price;
        //   if (
        //     !queryFilters.joining_fees_in_wei[`Decimals-${decimals}`].max ||
        //     value > queryFilters.joining_fees_in_wei[`Decimals-${decimals}`].max
        //   ) {
        //     queryFilters.joining_fees_in_wei[`Decimals-${decimals}`].max =
        //       value;
        //   }
        // }
      });
    }
    console.log("Query filters ", queryFilters);
    //setFilters({});
    setFilters(queryFilters);
  };

  const onFinished = () => {
    if (currEvent.status === "ongoing" && currEvent.is_joined)
      navigate(`/event/${currEvent.event_id}/${currEvent.vestibule_id}`);
    else handleModalClose();
  };

  return (
    <Box
      sx={{
        mt: {
          md: 6,
          xs: 5,
        },
        mb: 7,
      }}
    >
      {/* <JoiningCompleted
				open={joinCompletedModal}
				onClose={() => setJoinCompletedModal(false)}
			/> */}
      {/* <JoinEventSteps
				open={modal}
				ref1={approvalRef}
				ref2={smartContractRef}
				ref3={joiningEventRef}
				onClose={handleModalClose}
				active={modalActive}
			/> */}
      <BasicLoader
        open={modal.open}
        error={modal.error}
        completed={completed}
        onClose={handleModalClose}
        onFinished={onFinished}
      />
      <Stack
        sx={{
          flexDirection: {
            md: "row",
          },
        }}
        spacing={2}
      >
        <ResponsiveFilter onSubmit={onFiltersSubmit} />
        <Stack
          spacing={2}
          /* sx={{
						pl: {
							md: 2,
						},
					}} */
          sx={{
            width: {
              md: "150%",
            },
          }}
        >
          <ResponsiveTabs
            onTabsChanged={handleTabsChanged}
            opts={["Popular", "Upcoming", "Past"]}
          />
          {!isLoading && events ? (
            <Box
              sx={{
                px: {
                  md: 2,
                  xs: 1,
                },
                /* overflow: "hidden auto",
								maxHeight: "90vh" */
              }}
            >
              <AnimatePresence>
                {tab === 0 ? (
                  <PopularEvents
                    onEventSelected={handleEventClicked}
                    events={events.popular_events}
                    onJoinClicked={handleJoinClicked}
                  />
                ) : tab === 1 ? (
                  <UpcomingEvents
                    onEventSelected={handleEventClicked}
                    onJoinClicked={handleJoinClicked}
                    events={events.scheduled_events}
                  />
                ) : (
                  <PastEvents
                    onEventSelected={handleEventClicked}
                    onJoinClicked={handleJoinClicked}
                    events={events.completed_events}
                  />
                )}
              </AnimatePresence>
            </Box>
          ) : (
            <ResponsiveLoading />
          )}
        </Stack>
      </Stack>
      <Drawer
        anchor={width < 400 ? "bottom" : "right"}
        elevation={10}
        open={drawerOpen}
        variant="temporary"
        onClose={toggleDrawer}
        /* sx={{
				"-webkit-backdrop-filter": "blur(4px)",
				backdropFilter: "blur(4px)",
			}} */
      >
        <EventDetails
          handleJoinClicked={handleJoinClicked}
          event={selectedEvent}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
    </Box>
  );
}
