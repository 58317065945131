import { Avatar, Stack, Typography } from '@mui/material'
import React from 'react'
import Base from '../Base'
import Wallet from '../../../wallet/config.json'
import Circular from '../../ProgressBars/Circular'

export default function WalletConnecting({
    open,
    onClose,
    wallet
}) {
    return (
        <Base
            open={open}
            onClose={onClose}
        >
            <Stack
                spacing={2}
                justifyContent='center'
                alignItems='center'
            >
                <Stack
                    direction='row'
                    spacing={2}
                    alignItems='center'
                >
                    <Avatar
                        src={Wallet[wallet]['image']}
                        sx={{
                            width: 35,
                            height: 35
                        }}
                    />
                    <Typography
                        variant='button'
                        sx={{
                            fontSize: 22
                        }}
                    >
                        Connecting to {Wallet[wallet]['name']}
                    </Typography>
                </Stack>
                <Circular />
            </Stack>
        </Base>
    )
}
