import { Box, Button, Drawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TradeChart from "./Chart";
import CryptosInfo from "./CryptoInfo";
import Cryptos from "./Cryptos";

function WebUI({
  loading,
  coins,
  selected,
  setSelected,
  toggleDrawer,
  setLoading,
  setCryptoInfo,
  setDrawerType,
}) {
  return (
    <Box
      sx={{
        mt: 1,
        display: {
          xs: "none",
          xl: "flex",
        },
        height: "100%",
        opacity: loading ? 0.2 : 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <TradeChart data={selected} height={"100%"} setLoading={setLoading} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: "22vw" }}>
        <Cryptos
          data={coins}
          setSelected={setSelected}
          loading={loading}
          selected={selected}
        />
        <CryptosInfo
          data={selected}
          cryptoData={{
            crypto_current_price: coins?.filter(
              (i) => i.crypto_id === selected.crypto_id
            )[0].crypto_current_price,
            crypto_price_change_percent_24h: coins?.filter(
              (i) => i.crypto_id === selected.crypto_id
            )[0].crypto_price_change_percent_24h,
          }}
          toggleDrawer={toggleDrawer}
          setLoading={setLoading}
          setCryptoInfo={setCryptoInfo}
          setDrawerType={setDrawerType}
        />
      </Box>
    </Box>
  );
}

export default WebUI;
