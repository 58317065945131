import { Box, Typography } from "@mui/material";
import React from "react";

export default function NoPortfolio() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(80vh - 10rem)",
      }}
    >
      <Typography variant="h5" align="center" textTransform="uppercase">
        Please Start Trading to View Your Portfolio
      </Typography>
    </Box>
  );
}
