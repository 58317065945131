import React, { useState } from "react";
import { Box, Stack, Typography, CardActionArea, Divider } from "@mui/material";

export default function Tabs({ onTabsChanged, opts }) {
	const menu = opts;

	const [current, setCurrent] = useState(0);

	const handleTabChange = (index) => {
		setCurrent(index);
		onTabsChanged(index);
	};

	return (
		<Box
			sx={{
				width: "100%",
				background: "#1C1035"
			}}
		>
			<Box>
				<Stack
					direction="row"
					alignItems="center"
					width='100%'
					sx={{
						justifyContent: 'center',
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`
					}}
					divider={<Divider orientation="vertical" flexItem />}
				>
					{menu.map((item, index) => (
						<Stack
							key={index}
							direction='row'
							sx={{
								width: "100%",
								p: 2,
								"&:hover": {
									cursor: "pointer",
									background: (theme) => index === current ? theme.palette.secondary.main : theme.palette.secondary.dark + "30"
								},
								background:
									index === current
										? (theme) => theme.palette.secondary.main
										: "none",
								transition: '0.3s ease all'
							}}
							alignItems='center'
							justifyContent='center'
							onClick={() => handleTabChange(index)}
						>
							<Typography
								variant="subtitle1"
								fontWeight={600}
								color="#fff"
								align='center'
								sx={{
									fontSize: {
										xs: 16,
										md: 18,
									},
									color: index !== current ? 'white' : 'black',
								}}
							>
								{item.toUpperCase()}
							</Typography>
						</Stack>
					))}
				</Stack>
			</Box>
		</Box>
		/* <Box
			sx={{
				background: {
					md: '#1C1035'
				},
				pl: {
					md: 2,
				},
				width: "100%"
			}}
		>
			<Box
				sx={{
					mt: 1,
				}}
			>
				<Stack
					direction="row"
					spacing={3}
					alignItems="center"
					sx={{
						justifyContent: {
							md: 'left',
							xs: 'center'
						}
					}}
				>
					{menu.map((item, index) => (
						<CardActionArea
							key={index}
							sx={{
								width: "auto",
								p: 1,
							}}
							onClick={() => handleTabChange(index)}
						>
							<Typography
								variant="subtitle1"
								fontWeight={500}
								color="#fff"
								sx={{
									borderBottom:
										index === current
											? (theme) => `2px solid ${theme.palette.secondary.main}`
											: "none",
									fontSize: {
										xs: 14,
										md: 16,
									},
									pb: 1,
								}}
							>
								{item.toUpperCase()}
							</Typography>
						</CardActionArea>
					))}
				</Stack>
			</Box>
		</Box > */
	);
}
