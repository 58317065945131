import { IconButton, Stack, Typography } from '@mui/material'
import TradeableCoinsCards from '../../Cards/TradeableCoinsCards'
import React from 'react'
import Base from '../Base'
import { Cancel } from '@mui/icons-material'

export default function TradeableCoins({ open, onClose, coins }) {
    return (
        <Base
            open={open}
            onClose={onClose}
        >
            <Stack
                spacing={2}
                sx={{
                    minHeight: 500,
                    width: 270,
                    height: '40vh',
                }}
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography
                        variant='button'
                        sx={{
                            fontSize: 22
                        }}
                    >
                        Trade-able coins
                    </Typography>
                    <IconButton
                        onClick={onClose}
                    >
                        <Cancel />
                    </IconButton>
                </Stack>
                <Stack
                    sx={{
                        overflow: 'hidden auto',
                        pr: 2
                    }}
                    spacing={2}
                >
                    {coins?.map(coin => (
                        <TradeableCoinsCards
                            name={coin['coin.crypto_name']}
                            symbol={coin['coin.crypto_symbol']}
                            image={coin['coin.crypto_image']}
                            id={coin['crypto_id']}
                        />
                    ))}
                </Stack>
            </Stack>
        </Base>
    )
}
