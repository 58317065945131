import React, { useEffect, useRef, useState } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import { Box, Typography, Stack } from "@mui/material";
export default function Chart({ data }) {
  const chartContainerRef = useRef();
  const chart = useRef();
  const resizeObserver = useRef();
  const [hoverData, setHoverData] = useState(null);

  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: "#2A175099",
        textColor: "#fff",
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.35,
          bottom: 0.2,
        },
        borderVisible: false,
      },
      timeScale: {
        borderVisible: false,
      },
      grid: {
        horzLines: {
          color: "#382262",
          visible: false,
        },
        vertLines: {
          color: "#382262",
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
    });

    var series = chart.current.addAreaSeries({
      topColor: "#ffc50c80",
      bottomColor: "#38226290",
      lineColor: "#ffc50c",
      lineWidth: 3,
    });

    series.setData(data);

    chart.current.subscribeCrosshairMove((param) => {
      if (param.time) {
        const dataValue = param.seriesPrices.get(series);
        setHoverData({
          time: `${param.time.day} . ${param.time.month} . ${param.time.year}`,
          value: dataValue,
        });
      } else {
        setHoverData(null);
      }
    });
  }, []);

  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      chart.current.applyOptions({ width, height });
      setTimeout(() => {
        chart.current.timeScale().fitContent();
      }, 0);
    });

    resizeObserver.current.observe(chartContainerRef.current);

    return () => resizeObserver.current.disconnect();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "12px",
          left: "20px",
          zIndex: 10000,
        }}
      >
        <Stack spacing={1}>
          {hoverData && (
            <Typography variant="body2" fontSize="22px" letterSpacing={1.2}>
              ${Number(hoverData.value).toFixed(2)}
            </Typography>
          )}
          {hoverData && (
            <Typography variant="body2" fontSize="15px">
              {hoverData.time?.toString()}
            </Typography>
          )}
        </Stack>
      </Box>
      <Box
        ref={chartContainerRef}
        sx={{
          width: "100%",
          height: {
            xs: "35vh",
            xl: "50vh",
          },
        }}
      />
    </Box>
  );
}
