import { Avatar, Box, Paper, Skeleton, Stack } from '@mui/material'
import React from 'react'

export default function ResponsiveEventSkeleton() {
    const TypographySkeleton = ({ width, height }) => (
        <Skeleton
            variant='text'
            animation='wave'
            width={width || '180px'}
            height={height || '30px'}
        />
    )

    return (
        <Stack
            justifyContent='space-between'
            direction='row'
            sx={{
                display: 'flex',
                flex: 1,
                m: {
                    xs: 2,
                    md: 4
                },
            }}
        >
            <Stack
                direction='row'
                spacing={2}
                alignItems='center'
            >
                <Skeleton
                    variant='circular'
                    animation='wave'
                >
                    <Avatar
                        sx={{
                            width: 50,
                            height: 50
                        }}
                    />
                </Skeleton>
                <Stack>
                    <TypographySkeleton />
                    <TypographySkeleton width={80} />
                    <TypographySkeleton width={80} />
                </Stack>
            </Stack>
            <Stack
                sx={{
                    display: {
                        xs: 'none',
                        md: 'flex'
                    }
                }}
            >
                <TypographySkeleton width={120}/>
                <Stack
                    sx={{
                        alignItems: 'end'
                    }}
                >
                    <TypographySkeleton width={50} />
                    <TypographySkeleton width={80} />
                </Stack>
            </Stack>
        </Stack>
    )
}
