import { Avatar, CardActionArea, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import Wallets from '../../wallet/config.json'

export default function SupportedWalletsCards({ wallet, onConnectionRequest }) {
    return (
        <CardActionArea
            onClick={onConnectionRequest}
        >
            <Paper
                sx={{
                    background: (theme) => theme.palette.primary.darker,
                    p: 2
                }}
                elevation={0}
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography
                        variant='button'
                        sx={{
                            fontSize: 18
                        }}
                    >
                        {Wallets[wallet]['name']}
                    </Typography>
                    <Avatar
                        src={Wallets[wallet]['image']}
                        sx={{
                            width: 30,
                            height: 30
                        }}
                    />
                </Stack>
            </Paper>
        </CardActionArea>
    )
}
