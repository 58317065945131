import { Box } from '@mui/material'
import React from 'react'
import './style.css'

export default function SilhoutteLoader() {
    return (
        <Box
            sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: '80px',
                borderRadius: 2,
                zIndex: -1
            }}
            className='silhoutte'
        >

        </Box>
    )
}
