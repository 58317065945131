import * as Web3 from 'web3'
import CreateTournamentFactory from './abi/CreateTournamentFactory.json'

const client = new Web3(window.ethereum)
let account = localStorage.getItem('account')

if (window.ethereum) {
    window.ethereum.on('accountsChanged', (accounts) => {
        account = accounts[0]
    })
}

export { client, CreateTournamentFactory, account }