import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

export default function StyledTextField(props) {
  return (
    <TextField
      {...props}
      autoComplete='off'
      sx={{
        "& label.Mui-focused": {
          color: "white",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: (theme) => theme.palette.secondary.main,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#828282",
          },
          "&:hover fieldset": {
            borderColor: "white",
          },
          "&.Mui-focused fieldset": {
            borderColor: (theme) => theme.palette.secondary.main,
          },
        },
        '& input[type=number]': {
          '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        },
        ...props?.sx,
      }}
    />
  );
}
